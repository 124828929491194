<template>
    <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp" :key="componentKey">
      <div id="page-content" v-if="!viLoader">
      
        <div class="webNew-dispflex"   >
         
         <div class="backbox">
           <div class="back-arrow" @click="previousRoute">
             <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
           </div>
  
         </div>
         <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
            Enquiry
         </h4>
        </div>
        <div class="row mt-2">
          <div class="col-lg-12">
            <div class="widjet">
              <div class="widjethdr dispflex">
                <div style="width: auto">
                  <span class="iconsect blubg" style="padding-left: 11px"
                    ><i class="fas fa-book-reader"></i
                  ></span>
                  <h1>Manage Enquiry Admission</h1>
                </div>
              
  
                <div class="mt-1" style="margin-right: 31px">
                  <button
                    class="btn gry_bg tooltipt mpopup twoicn"
                    @click="openPoup"
                  >
                    <i
                      class="fas fa-book-open user1"
                      style="top: 3px; left: 2px"
                    ></i>
                    <p class="user2"><i class="fas fa-plus"></i></p>
  
                    <span class="tooltiptext">Create New Enquiry</span>
                  </button>
                </div>
              </div>
              <div class="p-3 widjetcontent">
                <div class="tabs" style="height: 2%; background: none">
                  <input
                    type="radio"
                    name="tabs"
                    @click="setActiveTab(1)"
                    id="NewEnquiryId"
                    :checked="currentTab == 1 ? 'checked' : ''"
                  />
                  <label
                    for="NewEnquiryId"
                    style="
                      border: 1px solid rgb(192, 196, 233);
                      border-radius: 15px 15px 0px 0px;
                    "
                    >New Enquiry [{{ newEnquiryList.length }}]</label
                  >
                  <div class="tab">
                    <div class="tbllist mt-1">
                      <div class="restable">
                        <div class="resrow resheader">
                          <!-- <div class="cell">S.No.</div> -->
                          <div class="cell">Candidate Name</div>
                          <div class="cell">Course</div>
                          <div class="cell">Enquiry Type</div>
                          <!-- <div class="cell">Contact</div> -->
                          <div class="cell">Status</div>
                          <div class="cell">Enquired Date</div>
                          <div class="cell">Counsellor</div>
                          <div class="cell">Action</div>
                        </div>
                        <div
                          class="resrow"
                          v-for="(data, index) in newEnquiryList"
                          :key="index"
                        >
                          <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                          <div class="cell" data-title="Candidate Name">{{  data.firstName + " " + data.lastName }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.className }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.enquirySourceType }}</div>
                          <!-- <div class="cell" data-title="Candidate Name">{{  data.className }}</div> -->
                          <div class="cell" data-title="Candidate Name">{{  data.enquiryStatus }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.enquiryDate | dateFormat }}</div>
                          <div class="cell" data-title="Candidate Name">
                            <select class="form-control" id="teacherId" v-model="data.counsellor" @change.prevent="changeCounsellor(data.counsellor,data._id)" >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, tIndex) in teacherList"
                            :key="tIndex"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}
                          </option>
                           
                        </select>
                          </div>
                         
                        
  
                          <div class="cell" data-title="Action">
                            <b-dropdown
                              id="dropdown-1"
                              text=""
                              right
                              style="margin-right: 3px"
                            >
                             
                              <b-dropdown-item  @click.prevent="openEditPoup(data)"  >Edit Enquiry </b-dropdown-item>
                              <b-dropdown-item  @click.prevent="updateStatus('isActive',data._id)"  >Mark as Inactive   </b-dropdown-item>
                              <b-dropdown-item  @click.prevent="updateStatus('Contacted',data._id)"  >Move to Next Stage</b-dropdown-item>
                              <b-dropdown-item  @click.prevent="updateStatus('Cancelled',data._id)"  >Cancel Enquiry</b-dropdown-item>
                              <b-dropdown-item  @click.prevent="confirmDelete(data._id)"  >Delete</b-dropdown-item>


                             
                            </b-dropdown>
  
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="tabs"
                    @click="setActiveTab(1)"
                    id="ContactedId"
                    :checked="currentTab == 2 ? 'checked' : ''"
                  />
                  <label
                    for="ContactedId"
                    style="
                      border: 1px solid rgb(192, 196, 233);
                      border-radius: 15px 15px 0px 0px;
                    "
                    >Contacted [{{ contactedEnquiryList.length }}]</label
                  >
                  <div class="tab">
                    <div class="tbllist mt-1">
                      <div class="restable">
                        <div class="resrow resheader">
                          <!-- <div class="cell">S.No.</div> -->
                          <div class="cell">Candidate Name</div>
                          <div class="cell">Course</div>
                          <div class="cell">Enquiry Type</div>
                          <!-- <div class="cell">Contact</div> -->
                          <div class="cell">Status</div>
                          <div class="cell">Enquired Date</div>
                          <div class="cell">Counsellor</div>
                          <div class="cell">Action</div>
                        </div>
                        <div
                          class="resrow"
                          v-for="(data, index) in contactedEnquiryList"
                          :key="index"
                        >
                           <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                           <div class="cell" data-title="Candidate Name">{{  data.firstName + " " + data.lastName }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.className }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.enquirySourceType }}</div>
                          <!-- <div class="cell" data-title="Candidate Name">{{  data.className }}</div> -->
                          <div class="cell" data-title="Candidate Name">{{  data.enquiryStatus }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.enquiryDate | dateFormat }}</div>
                          <div class="cell" data-title="Candidate Name">
                            <select class="form-control" id="teacherId" v-model="data.counsellor" @change.prevent="changeCounsellor(data.counsellor,data._id)" >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, tIndex) in teacherList"
                            :key="tIndex"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}
                          </option>
                           
                        </select>
                          </div>
                         
                        
  
                          <div class="cell" data-title="Action">
                            <b-dropdown
                              id="dropdown-1"
                              text=""
                              right
                              style="margin-right: 3px"
                            >
                             
                            <b-dropdown-item  @click.prevent="openEditPoup(data)"  >Edit Enquiry </b-dropdown-item>
                              <!-- <b-dropdown-item  @click.prevent="updateStatus('isActive',data._id)"  >Mark as Inactive   </b-dropdown-item> -->
                              <b-dropdown-item  @click.prevent="updateStatus('In Progress',data._id)"  >Move to Next Stage</b-dropdown-item>
                              <b-dropdown-item  @click.prevent="updateStatus('Not Interested',data._id)"  >Not Interested</b-dropdown-item>
                              <b-dropdown-item  @click.prevent="confirmDelete(data._id)"  >Delete</b-dropdown-item>



                             
                            </b-dropdown>
  
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="tabs"
                    @click="setActiveTab(1)"
                    id="Counsellingid"
                    :checked="currentTab == 3 ? 'checked' : ''"
                  />
                  <label
                    for="Counsellingid"
                    style="
                      border: 1px solid rgb(192, 196, 233);
                      border-radius: 15px 15px 0px 0px;
                    "
                    >Counselling [{{ counsellingEnquiryList.length }}]</label
                  >
                  <div class="tab">
                    <div class="tbllist mt-1">
                      <div class="restable">
                        <div class="resrow resheader">
                          <!-- <div class="cell">S.No.</div> -->
                          <div class="cell">Candidate Name</div>
                          <div class="cell">Course</div>
                          <div class="cell">Enquiry Type</div>
                          <!-- <div class="cell">Contact</div> -->
                          <div class="cell">Status</div>
                          <div class="cell">Enquired Date</div>
                          <div class="cell">Counsellor</div>
                          <div class="cell">Action</div>
                        </div>
                        <div
                          class="resrow"
                          v-for="(data, index) in counsellingEnquiryList"
                          :key="index"
                        >
                            <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                            <div class="cell" data-title="Candidate Name">{{  data.firstName + " " + data.lastName }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.className }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.enquirySourceType }}</div>
                          <!-- <div class="cell" data-title="Candidate Name">{{  data.className }}</div> -->
                          <div class="cell" data-title="Candidate Name">{{  data.enquiryStatus }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.enquiryDate | dateFormat }}</div>
                          <div class="cell" data-title="Candidate Name">
                            <select class="form-control" id="teacherId" v-model="data.counsellor" @change.prevent="changeCounsellor(data.counsellor,data._id)" >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, tIndex) in teacherList"
                            :key="tIndex"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}
                          </option>
                           
                        </select>
                          </div>
                         
                        
  
                          <div class="cell" data-title="Action">
                            <b-dropdown
                              id="dropdown-1"
                              text=""
                              right
                              style="margin-right: 3px"
                            >
                             
                            <b-dropdown-item  @click.prevent="openEditPoup(data)"  >Edit Enquiry </b-dropdown-item>
                              <!-- <b-dropdown-item  @click.prevent="updateStatus('isActive',data._id)"  >Mark as Inactive   </b-dropdown-item> -->
                              <b-dropdown-item  @click.prevent="updateStatus('Admission in Progress',data._id)"  >Move to Next Stage</b-dropdown-item>
                              <b-dropdown-item  @click.prevent="updateStatus('Rejected',data._id)"  >Reject</b-dropdown-item>
                              <b-dropdown-item  @click.prevent="confirmDelete(data._id)"  >Delete</b-dropdown-item>


                             
                            </b-dropdown>
  
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="tabs"
                    @click="setActiveTab(1)"
                    id="admissionid"
                    :checked="currentTab == 4 ? 'checked' : ''"
                  />
                  <label
                    for="admissionid"
                    style="
                      border: 1px solid rgb(192, 196, 233);
                      border-radius: 15px 15px 0px 0px;
                    "
                    >Admission [{{ admissionEnquiryList.length }}]</label
                  >
                  <div class="tab">
                    <div class="tbllist mt-1">
                      <div class="restable">
                        <div class="resrow resheader">
                          <!-- <div class="cell">S.No.</div> -->
                          <div class="cell">Candidate Name</div>
                          <div class="cell">Course</div>
                          <div class="cell">Enquiry Type</div>
                          <!-- <div class="cell">Contact</div> -->
                          <div class="cell">Status</div>
                          <div class="cell">Enquired Date</div>
                          <div class="cell">Counsellor</div>
                          <div class="cell">Action</div>
                        </div>
                        <div
                          class="resrow"
                          v-for="(data, index) in admissionEnquiryList"
                          :key="index"
                        >
                           <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                           <div class="cell" data-title="Candidate Name">{{  data.firstName + " " + data.lastName }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.className }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.enquirySourceType }}</div>
                          <!-- <div class="cell" data-title="Candidate Name">{{  data.className }}</div> -->
                          <div class="cell" data-title="Candidate Name">{{  data.enquiryStatus }}</div>
                          <div class="cell" data-title="Candidate Name">{{  data.enquiryDate | dateFormat }}</div>
                          <div class="cell" data-title="Candidate Name">
                            <select class="form-control" id="teacherId" v-model="data.counsellor" @change.prevent="changeCounsellor(data.counsellor,data._id)" >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, tIndex) in teacherList"
                            :key="tIndex"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}
                          </option>
                           
                        </select>
                          </div>
                         
                        
  
                          <div class="cell" data-title="Action">
                            <b-dropdown
                              id="dropdown-1"
                              text=""
                              right
                              style="margin-right: 3px"
                            >
                             
                            <b-dropdown-item  @click.prevent="openEditPoup(data)"  >Edit Enquiry </b-dropdown-item>
                              <b-dropdown-item  @click.prevent="updateStatus('Admission Link Sent',data._id)"  >Admission Link Sent</b-dropdown-item>
                              <b-dropdown-item  @click.prevent="admissionConvert(data)"  >Admission Convert</b-dropdown-item>
                              <b-dropdown-item  @click.prevent="confirmDelete(data._id)"  >Delete</b-dropdown-item>



                             
                            </b-dropdown>
  
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
      <div>
        <b-modal id="addstudpop" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closePopUp">×</button>
      <div class="contact-form">
        <h2>New Enquiry</h2>
        <br />
        <div style="height: 580px; overflow-y: auto">
          <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    v-validate="'required'"
                    name="studentFirstName"
                    v-model="enquiryDetails.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
              
               <div class="form-group input-group col-md-4 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    name="studentLastName"
                    v-model="enquiryDetails.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
             
                  

                    

              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="date"
                    :max="minDob"
                    name="DOB"
                    v-model="enquiryDetails.dob"
                    class="form-control form-input"
                    id="stdDob"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                        
                          <select
                          v-validate="'required'"
                            class="form-control form-input"
                            name="applyClass"
                            v-model="enquiryDetails.class"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in classList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.className }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.applyClass')"
                            class="vi-error"
                            style="top: -1em"
                            >Class is required</label
                          >
                          <label for="stdFirstName" v-else
                            >Class <span class="required">*</span></label
                          >
                        </span>
                      </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                    <select
                            v-validate="'required'"
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="enquiryDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidateOne.gender')"
                            class="vi-error"
                            style="top: -1em"
                            >Gender is required</label
                          >
                          <label for="firstName" v-else
                            >Gender <span class="required">*</span></label
                          >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="Blood Group"
                            class="form-control form-input"
                            id="bloodGroup"
                            v-model="enquiryDetails.bloodGroup"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(blood, index) in bloodGroupList"
                              :key="index"
                              :value="blood"
                            >
                              {{ blood }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidateOne.Blood Group')"
                            class="vi-error"
                            style="top: -1em"
                            >Blood group is required</label
                          >
                          <label for="bloodGroup" v-else
                            >Blood Group <span class="required">*</span></label
                          >
                        </span>
              </div>
               
                  
              
             
           
              <fieldset class="accordion-group-container">
                
               

               

                 <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    PARENT DETAILS
                  </label>
                    <div class="form-row">
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      </div>
                       <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="enquiryDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                     </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span
                          class="has-float-label"
                          v-if="enquiryDetails.pointOfContact == 'Father'"
                        >
                          <input
                         
                            v-validate="enquiryDetails.pointOfContact == 'Father'
                                ? 'required'
                                : false
                            "
                            type="text"
                            name="fthrname"
                            v-model="enquiryDetails.parentfirstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrname')"
                            class="vi-error"
                            style="top: -1em"
                            >Father First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father First Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="enquiryDetails.pointOfContact == 'Mother'"
                        >
                          <input
                            type="text"
                           
                            name="Mother"
                            v-validate="
                              enquiryDetails.pointOfContact == 'Mother'
                                ? 'required'
                                : false
                            "
                            v-model="enquiryDetails.parentfirstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Mother')"
                            class="vi-error"
                            style="top: -1em"
                            >Mother First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother First Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="enquiryDetails.pointOfContact == 'Guardian'"
                        >
                          <input
                            type="text"
                            name="Guardian"
                            v-validate="
                              enquiryDetails.pointOfContact == 'Guardian'
                                ? 'required'
                                : false
                            "
                            v-model="enquiryDetails.parentfirstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Guardian')"
                            class="vi-error"
                            style="top: -1em"
                            >Guardian First Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian First Name
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                    
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span
                          class="has-float-label"
                          v-if="enquiryDetails.pointOfContact == 'Father'"
                        >
                          <input
                            v-validate="
                              enquiryDetails.pointOfContact == 'Father'
                                ? 'required'
                                : false
                            "
                            type="text"
                            name="fthlastName"
                            v-model="enquiryDetails.parentlastName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthlastName')"
                            class="vi-error"
                            style="top: -1em"
                            >Father Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father Last Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="enquiryDetails.pointOfContact == 'Mother'"
                        >
                          <input
                            type="text"
                            name="motherLastNameval"
                            v-validate="
                              enquiryDetails.pointOfContact == 'Mother'
                                ? 'required'
                                : false
                            "
                            v-model="enquiryDetails.parentlastName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.motherLastNameval')"
                            class="vi-error"
                            style="top: -1em"
                            >Mother Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother Last Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="enquiryDetails.pointOfContact == 'Guardian'"
                        >
                          <input
                            type="text"
                            name="Guardianaaasa"
                            v-validate="
                              enquiryDetails.pointOfContact == 'Guardian'
                                ? 'required'
                                : false
                            "
                            v-model="enquiryDetails.parentlastName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Guardianaaasa')"
                            class="vi-error"
                            style="top: -1em"
                            >Guardian Last Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian Last Name
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3" >
                        <span class="has-float-label">
                          <input
                            type="email"
                            v-validate="'required'"
                            name="ptemail"
                            v-model="enquiryDetails.parentEmail"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.ptemail')"
                            class="vi-error"
                            style="top: -1em"
                            >Primary Email is required</label
                          >
                          <label for="ptemailid" v-else
                            >Primary Email
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-3 pr-md-3 mt-3" >
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            name="studentMobileNumber"
                            v-model="enquiryDetails.parentPhoneNo"
                            class="form-control form-input"
                          />
                          
                            <label
                            v-if="errors.first('studentvalidate.studentMobileNumber')"
                            class="vi-error"
                            style="top: -1em"
                            >Mobile is required</label
                          >
                          <label for="ptemailid" v-else
                            >Mobile
                            <span class="required">*</span></label
                          >

                        </span>
                      </div>

              
                      
                      
                    </div>
                </div>
                <div >
                 
                 <label
                   style="margin: 0"
                   for="accordiongroup-1"
                   class="blutitle accordion-label"
                 >
                   ASSIGN COUNSELLOR & COMMENTS
                 </label>
                   <div class="form-row">
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                     </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                       <span class="has-float-label">
                         <select
                           v-validate="'required'"
                           name="counsillor"
                           class="form-control form-input"
                           v-model="enquiryDetails.counsellor"
                         >
                           <option :value="null">-- Select --</option>
                           <option
                            v-for="(item, tIndex) in teacherList"
                            :key="tIndex"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}
                          </option>
                         </select>
                         <label
                           v-if="errors.first('studentvalidate.counsillor')"
                           class="vi-error"
                           style="top:-1em"
                           >Counsellor is required</label
                         > 
                         <label for="firstName" v-else
                           >Counsellor <span class="required">*</span></label
                         >
                       </span>
                     </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                    </div>
                    
                   
                     <div class="form-group input-group col-12 mt-md-3">
                  <span class="has-float-label">
                    <textarea
                      id="notes"
                      class="form-control"
                      style="min-height: 90px; resize: none"
                      placeholder="sssss"
                    ></textarea>
                    <!-- <label for="notes"
                      >Notes <span class="required">*</span></label
                    > -->
                  </span>
                </div>

             
                     
                     
                   </div>
               </div>
                
                 
              </fieldset>
            </div>
            
          </form>
        </div>
        <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon">
               
              </div> -->
              <div class="text-center dk_iconsml">
                
                <!-- <button v-if="editId" 
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateStudent"
                >
                    <span>Update</span>
                </button> -->
                 <button 
                  type="button"
                  class="btn btnsml"
                  
                  @click.prevent="submitApplication"
                  id="svbtn"
                >
                    <span>Create</span>
                </button>
                <button type="button" @click="closePopUp" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
            </div>
      </div>
    </b-modal>
    <b-modal id="editstudpop" class="modal" >
      <button type="button" class="close"  @click.prevent="closeEditPopUp">×</button>
      <div class="contact-form">
        <h2>Edit Enquiry</h2>
        <br />
        <div style="height: 580px; overflow-y: auto">
          <form data-vv-scope="studentEditvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    v-validate="'required'"
                    name="studentFirstName"
                    v-model="editEnquiryDetails.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label
                    v-if="errors.first('studentEditvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
              
               <div class="form-group input-group col-md-4 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    name="studentLastName"
                    v-model="editEnquiryDetails.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                  />
                  <label
                    v-if="errors.first('studentEditvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
             
                  

                    

              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="date"
                    :max="minDob"
                    name="DOB"
                    v-model="editEnquiryDetails.dob"
                    class="form-control form-input"
                    id="stdDob"
                  />
                  <label
                    v-if="errors.first('studentEditvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                        
                          <select
                          v-validate="'required'"
                            class="form-control form-input"
                            name="applyClass"
                            v-model="editEnquiryDetails.class"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in classList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.className }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentEditvalidate.applyClass')"
                            class="vi-error"
                            style="top: -1em"
                            >Class is required</label
                          >
                          <label for="stdFirstName" v-else
                            >Class <span class="required">*</span></label
                          >
                        </span>
                      </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                    <select
                            v-validate="'required'"
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="editEnquiryDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentEditvalidate.gender')"
                            class="vi-error"
                            style="top: -1em"
                            >Gender is required</label
                          >
                          <label for="firstName" v-else
                            >Gender <span class="required">*</span></label
                          >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="Blood Group"
                            class="form-control form-input"
                            id="bloodGroup"
                            v-model="editEnquiryDetails.bloodGroup"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(blood, index) in bloodGroupList"
                              :key="index"
                              :value="blood"
                            >
                              {{ blood }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentEditvalidate.Blood Group')"
                            class="vi-error"
                            style="top: -1em"
                            >Blood group is required</label
                          >
                          <label for="bloodGroup" v-else
                            >Blood Group <span class="required">*</span></label
                          >
                        </span>
              </div>
               
                  
              
             
           
              <fieldset class="accordion-group-container">
                
               

               

                 <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    PARENT DETAILS
                  </label>
                    <div class="form-row">
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      </div>
                       <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="editEnquiryDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentEditvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                     </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span
                          class="has-float-label"
                          v-if="editEnquiryDetails.pointOfContact == 'Father'"
                        >
                          <input
                         
                            v-validate="editEnquiryDetails.pointOfContact == 'Father'
                                ? 'required'
                                : false
                            "
                            type="text"
                            name="fthrname"
                            v-model="editEnquiryDetails.parentfirstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentEditvalidate.fthrname')"
                            class="vi-error"
                            style="top: -1em"
                            >Father First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father First Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="editEnquiryDetails.pointOfContact == 'Mother'"
                        >
                          <input
                            type="text"
                           
                            name="Mother"
                            v-validate="
                              editEnquiryDetails.pointOfContact == 'Mother'
                                ? 'required'
                                : false
                            "
                            v-model="editEnquiryDetails.parentfirstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentEditvalidate.Mother')"
                            class="vi-error"
                            style="top: -1em"
                            >Mother First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother First Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="editEnquiryDetails.pointOfContact == 'Guardian'"
                        >
                          <input
                            type="text"
                            name="Guardian"
                            v-validate="
                              editEnquiryDetails.pointOfContact == 'Guardian'
                                ? 'required'
                                : false
                            "
                            v-model="editEnquiryDetails.parentfirstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentEditvalidate.Guardian')"
                            class="vi-error"
                            style="top: -1em"
                            >Guardian First Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian First Name
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                    
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span
                          class="has-float-label"
                          v-if="editEnquiryDetails.pointOfContact == 'Father'"
                        >
                          <input
                            v-validate="
                              editEnquiryDetails.pointOfContact == 'Father'
                                ? 'required'
                                : false
                            "
                            type="text"
                            name="fthlastName"
                            v-model="editEnquiryDetails.parentlastName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentEditvalidate.fthlastName')"
                            class="vi-error"
                            style="top: -1em"
                            >Father Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father Last Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="editEnquiryDetails.pointOfContact == 'Mother'"
                        >
                          <input
                            type="text"
                            name="motherLastNameval"
                            v-validate="
                              editEnquiryDetails.pointOfContact == 'Mother'
                                ? 'required'
                                : false
                            "
                            v-model="editEnquiryDetails.parentlastName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentEditvalidate.motherLastNameval')"
                            class="vi-error"
                            style="top: -1em"
                            >Mother Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother Last Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="editEnquiryDetails.pointOfContact == 'Guardian'"
                        >
                          <input
                            type="text"
                            name="Guardianaaasa"
                            v-validate="
                              editEnquiryDetails.pointOfContact == 'Guardian'
                                ? 'required'
                                : false
                            "
                            v-model="editEnquiryDetails.parentlastName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentEditvalidate.Guardianaaasa')"
                            class="vi-error"
                            style="top: -1em"
                            >Guardian Last Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian Last Name
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3" >
                        <span class="has-float-label">
                          <input
                            type="email"
                            v-validate="'required'"
                            name="ptemail"
                            v-model="editEnquiryDetails.parentEmail"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentEditvalidate.ptemail')"
                            class="vi-error"
                            style="top: -1em"
                            >Primary Email is required</label
                          >
                          <label for="ptemailid" v-else
                            >Primary Email
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-3 pr-md-3 mt-3" >
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            name="studentMobileNumber"
                            v-model="editEnquiryDetails.parentPhoneNo"
                            class="form-control form-input"
                          />
                          
                            <label
                            v-if="errors.first('studentEditvalidate.studentMobileNumber')"
                            class="vi-error"
                            style="top: -1em"
                            >Mobile is required</label
                          >
                          <label for="ptemailid" v-else
                            >Mobile
                            <span class="required">*</span></label
                          >

                        </span>
                      </div>

              
                      
                      
                    </div>
                </div>
                <div >
                 
                 <label
                   style="margin: 0"
                   for="accordiongroup-1"
                   class="blutitle accordion-label"
                 >
                   ASSIGN COUNSELLOR & COMMENTS
                 </label>
                   <div class="form-row">
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                     </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                       <span class="has-float-label">
                         <select
                           v-validate="'required'"
                           name="counsillor"
                           class="form-control form-input"
                           v-model="editEnquiryDetails.counsellor"
                         >
                           <option :value="null">-- Select --</option>
                           <option
                            v-for="(item, tIndex) in teacherList"
                            :key="tIndex"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}
                          </option>
                         </select>
                         <label
                           v-if="errors.first('studentEditvalidate.counsillor')"
                           class="vi-error"
                           style="top:-1em"
                           >Counsellor is required</label
                         > 
                         <label for="firstName" v-else
                           >Counsellor <span class="required">*</span></label
                         >
                       </span>
                     </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                    </div>
                    
                   
                     <div class="form-group input-group col-12 mt-md-3">
                  <span class="has-float-label">
                    <textarea
                      id="notes"
                      class="form-control"
                      style="min-height: 90px; resize: none"
                      placeholder="sssss"
                      v-model="editEnquiryDetails.comments"
                    ></textarea>
                    <!-- <label for="notes"
                      >Notes <span class="required">*</span></label
                    > -->
                  </span>
                </div>

             
                     
                     
                   </div>
               </div>
                
                 
              </fieldset>
            </div>
            
          </form>
        </div>
        <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon">
               
              </div> -->
              <div class="text-center dk_iconsml">
                
                <!-- <button v-if="editId" 
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateStudent"
                >
                    <span>Update</span>
                </button> -->
                 <button 
                  type="button"
                  class="btn btnsml"
                  
                  @click.prevent="updateApplication"
                  id="svbtn"
                >
                    <span>Update</span>
                </button>
                <button type="button"  class="btn btncl clsmdl" id="clbtn" @click="closeEditPopUp">
                     <span>Cancel</span>
                </button>
              </div>
            </div>
      </div>
    </b-modal>
       
      </div>
  
      <vi-spinner
        v-if="viLoader"
        text="Loading..."
        textColor="vi-brand-color"
        class="flex-fill h-100 vi-fs12"
        style="
          width: 100%;
          z-index: 111;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 1rem;
        "
      />
    </div>
  </template>
  <script>
  import ViSpinner from "../Common/ViSpinner";
  import ViService from "@/services/ViService";
  import errorLog from "@/utils/errorLog";
  import secureUI from "../../utils/secureUI";
  import VueMonthlyPicker from "vue-monthly-picker";
  import "vue-select/dist/vue-select.css";
  import Select from "vue-select";
  
  export default {
    name: "candidates",
    data() {
      return {
        componentKey: 0,
        viLoader: false,
        currentTab: 1,
        currentPopUpTab: 1,
        currentSubTab: 2.1,
        isVerifyCode: false,
        teacherList: [],
        candidateEditDetails: {},
        selectAcademicYear: null,
        comments: "",
        enquiryDetails: {
          enquirySourceType: '',
          enquiryDate: '',
          enquiryStatus: '',
          comments: '',
          counsellor: null,
          firstName: "",
          lastName: "",
          dob: null,
          pointOfContact: "Father",
          academicYear: null,
          gender: "Male",
          classApplied: "",
          bloodGroup: null,
          class: null,
          className: "",
          parentfirstName: "",
          parentlastName: "",
          parentEmail: "",
          parentPhoneNo: null
        },
      
        editEnquiryDetails: {},
        offline: "Online",
        categoryList: ["General/OC","BC","SC","MBC","ST","Minority"],
        stateList: [
           "Andaman and Nicobar Islands",
          "Andhra Pradesh",
           "Arunachal Pradesh",
           "Assam",
           "Bihar",
           "Chandigarh",
           "Chhattisgarh",
           "Dadra and Nagar Haveli",
           "Daman and Diu",
           "Delhi",
           "Goa",
           "Gujarat",
           "Haryana",
           "Himachal Pradesh",
           "Jammu and Kashmir",
           "Jharkhand",
           "Karnataka",
           "Kerala",
           "Lakshadweep",
           "Madhya Pradesh",
           "Maharashtra",
           "Manipur",
           "Meghalaya",
           "Mizoram",
           "Nagaland",
           "Odisha",
           "Puducherry",
           "Punjab",
           "Rajasthan",
           "Sikkim",
          "Tamil Nadu",
          "Telangana",
           "Tripura",
           "Uttar Pradesh",
           "Uttarakhand",
           "West Bengal",
        ],
        statusList: [
          "Submitted",
          "Active",
          "Enrolled",
          "Need More Info",
          "Documents Missing",
          "Assessment scheduled",
        ],
        pointOfList: ["Father", "Mother", "Guardian"],
        genderList: ["Male", "Female"],
        newApplicationList: [],
        bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"],
        classList: [],
        newEnquiryList: [],
        contactedEnquiryList: [],
        counsellingEnquiryList: [],
        admissionEnquiryList: []
       
      };
    },
    created() {
      //this.getAcademicYearList();
      // this.getOnlineAdmissionList("new");
      this.setDob()
      this.getTeacherList()
      this.getClassList();
      this.getEnquiryAllList()
    //   this.getNewOnlineAdmissionList();
    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
    },
   
    methods: {
      setDob(){
  
          let start = new Date()
  
          let startmonth = start.getMonth() + 1
          let startdate = start.getDate()
          let startYear = start.getFullYear()
  
          if (startdate < 10) {
            startdate = '0' + startdate;
          }
          if (startmonth < 10) {
            startmonth = '0' + startmonth;
          }
          this.minDob = startYear + "-" + startmonth + "-" + startdate;
  
      },
      async changeCounsellor(data,id){
        if(data){

          let userData = secureUI.sessionGet("user");

          let obj = {
             _id: id,
             counsellor: data
          }

         const response = await ViService.viXPost("/contact/updateEnquiryStaus",obj,userData.token);

        if (response.isSuccess) {
         
          this.$toasted.success(response.message);
          this.getEnquiryAllList()
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }

         
      //  }
        }

      },

      async submitApplication() {
      let isFormValid = false;

      await this.$validator.validateAll("studentvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let getclassName = this.classList.find((x) => x._id == this.enquiryDetails.class);
          if (getclassName && getclassName.className) {
            this.enquiryDetails.className = getclassName.className;
          }

         const response = await ViService.viXPost("/contact/enquiry/new",this.enquiryDetails,userData.token);

        if (response.isSuccess) {
         
          this.$toasted.success(response.message);
          this.closePopUp()
          this.getEnquiryAllList()
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }

          // ViService.viXPost(
          //   "/contact/enquiry/new",
          //   this.enquiryDetails,
          //   userData.token
          // )
          //   .then((res) => {
          //     if (res.isSuccess) {
          //       console.log("sdsdsd",res)
          //       // if(res.data && res.data._id)
          //       // this.contactDetails['_id'] = res.data._id
          //       // this.currentPopUpTab = 2

          //       // if(res.data && res.data.eligibilityCriteriaDocuments && res.data.eligibilityCriteriaDocuments.length > 0){
          //       // this.contactDetails.eligibilityCriteriaDocuments = res.data.eligibilityCriteriaDocuments

          //       // }
              
          //     } else {
          //       this.$toasted.error(res.message);
          //     }
          //   })
          //   .catch((e) => {
          //     let eData = errorLog.apiErrorLog(e);
          //     this.$toasted.error(eData);
          //   });
        }
        // }
      }
      },
      async updateStatus(type,id) {
      // let isFormValid = false;

      // await this.$validator.validateAll("studentvalidate").then((result) => {
      //   isFormValid = result;
      // });

     // if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let obj = {
             _id: id
          }

           if(type == 'isActive'){
            obj['isActive'] = false
           }     

           if(type == 'Contacted'){
            obj['enquiryStatus'] = 'Contacted' 
           }
           if(type == 'Cancelled'){
            obj['enquiryStatus'] = 'Cancelled' 
           }

           if(type == 'Not Interested'){
            obj['enquiryStatus'] = 'Not Interested' 
           }
           if(type == 'Invite Parent'){
            obj['enquiryStatus'] = 'Invite Parent' 
           }

           if(type == 'In Progress'){
            obj['enquiryStatus'] = 'In Progress' 
           }

           if(type == 'Rejected'){
            obj['enquiryStatus'] = 'Rejected' 
           }
           if(type == 'Admission in Progress'){
            obj['enquiryStatus'] = 'Admission in Progress' 
           }

         const response = await ViService.viXPost("/contact/updateEnquiryStaus",obj,userData.token);

        if (response.isSuccess) {
         
          this.$toasted.success(response.message);
          //this.closePopUp()
          this.getEnquiryAllList()
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }

         
      //  }
        
      }
      },
      async admissionConvert(data) {
      // let isFormValid = false;

      // await this.$validator.validateAll("studentvalidate").then((result) => {
      //   isFormValid = result;
      // });

     // if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
         
         const response = await ViService.viXPost("/contact/enquiryToadmissionConvert",data,userData.token);

        if (response.isSuccess) {
         
          this.$toasted.success(response.message);
          //this.closePopUp()
          this.getEnquiryAllList()
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }

         
      //  }
        
      }
      },
    async updateApplication() {
      let isFormValid = false;

      await this.$validator.validateAll("studentEditvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let getclassName = this.classList.find((x) => x._id == this.editEnquiryDetails.class);
          if (getclassName && getclassName.className) {
            this.editEnquiryDetails.className = getclassName.className;
          }

         const response = await ViService.viXPost("/contact/enquiry/update",this.editEnquiryDetails,userData.token);

        if (response.isSuccess) {
         
          this.$toasted.success(response.message);
          this.closeEditPopUp()
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }

         
        }
        // }
      }
    },
    
  
      
      async getClassList() {
        let userData = secureUI.sessionGet("user");
  
        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          this.classList = [];
          const response = await ViService.viXGet(
            "/contact/getClassList",
            userData.token
          );
          if (response.isSuccess) {
            this.classList = secureUI.secureGet(response.data);
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      },
      setActiveTab(index) {
        this.currentTab = index;
        // if(index == 2){
        //   this.currentSubTab = 2.1
        // }
        // if(index == 3){
        //   this.currentSubTab = 3.1
        // }
        // if(index == 5){
        //   this.currentSubTab = 5.1
        // }
  
      },
      openPoup() {
    //   this.currentTab = 1;
    //   this.isEdit = true
    //   this.isShowEditIcon = false

    //   this.currentPopUpTab = 1,
      this.$bvModal.show("addstudpop");
     // this.currentTab = 1;

      //this.clearForm();
    },
    closePopUp(){
      this.$bvModal.hide("addstudpop");
      this.formClear()
    },
    openEditPoup(data) {
      this.editEnquiryDetails = data
      if(this.editEnquiryDetails.dob){
        this.editEnquiryDetails.dob = this.editEnquiryDetails.dob.substring(0,10);
      }
      this.$bvModal.show("editstudpop");
    
    },
    async confirmDelete(id) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
      //  this.viLoader = true;
        const response = await ViService.viDelete(`/contact/deleteEnquiry/${id}`,userData.token);

      if (response.isSuccess) {
     // this.$bvModal.hide("deleteStudentPopup");
      this.getEnquiryAllList()
      this.$toasted.success(response.message);
      } else {
            this.$toasted.error(response.message);
            if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
        }
      }
      }
      },
    closeEditPopUp(){
      this.$bvModal.hide("editstudpop");
      this.editFormClear()
    },
    
    formClear(){
      this.enquiryDetails = {
          enquirySourceType: '',
          enquiryDate: '',
          enquiryStatus: '',
          comments: '',
          counsellor: null,
          firstName: "",
          lastName: "",
          dob: null,
          pointOfContact: "Father",
          academicYear: null,
          gender: "Male",
          classApplied: "",
          bloodGroup: null,
          class: null,
          className: "",
          parentfirstName: "",
          parentlastName: "",
          parentEmail: "",
          parentPhoneNo: null
        }
    },
    editFormClear(){
      this.editEnquiryDetails = {}
    },
    async getTeacherList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/teacher/getUserTeacherList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.teacherList = secureUI.secureGet(response.data).teacherList;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
      previousRoute() {
        let userData = secureUI.sessionGet("user");
  
        if (userData.userDetails.account.version == "freeVersion") {
          localStorage.setItem("activeTab", "/enrollment/school");
          localStorage.setItem("previousTab", "/enrollment/school");
          this.$router.push({ path: "/enrollment/school" });
        } else {
          // let data = localStorage.getItem("previousTab");
          // localStorage.setItem("activeTab", data);
          // localStorage.setItem("previousTab", this.$route.path);
          // this.$router.push({ path: data });
          localStorage.setItem("activeTab", "/admission/online/home");
          localStorage.setItem("previousTab", "/admission/online/home");
          this.$router.push({ path: "/admission/online/home" });
        }
      },
      async getEnquiryAllList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       
        const response = await ViService.viXGet(
          `/admission/geEnquiryListView`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          if (resultData.length > 0) {

            this.newEnquiryList = resultData.filter((x) => x.enquiryStatus == "Created" || x.enquiryStatus == 'Cancelled') || []
            this.contactedEnquiryList = resultData.filter((x) => x.enquiryStatus == "Contacted" || x.enquiryStatus == 'Not Interested') || []
            this.counsellingEnquiryList = resultData.filter((x) => x.enquiryStatus == "Not Started" || x.enquiryStatus == 'In Progress') || []
            this.admissionEnquiryList = resultData.filter((x) => x.enquiryStatus == "Converted" || x.enquiryStatus == 'Invite Parent' || x.enquiryStatus == 'Admission in Progress') || []

          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
      redirectRoute(vipath) {
        if (this.$route.path !== vipath) this.$router.push({ path: vipath });
      }
    },
    components: {
      "v-select": Select,
      ViSpinner,
    },
  };
  </script>
  