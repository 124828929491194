<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp" :key="componentKey">
    <div id="page-content" v-if="!viLoader">
      <!-- <div class="dispflex">
        <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
     </div>

       </div>
        <h1 class="hdrtitle" style="margin-top: 0px">Online Admission</h1>
       
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Online Admission
       </h4>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-book-reader"></i
                ></span>
                <h1>Manage Admission</h1>
              </div>
            

              <div class="mt-1" style="margin-right: 31px">
                <button
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click="openPoup"
                >
                  <i
                    class="fas fa-book-open user1"
                    style="top: 3px; left: 2px"
                  ></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>

                  <span class="tooltiptext">Create New Application</span>
                </button>
              </div>
            </div>
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input
                  type="radio"
                  name="tabs"
                  @click="setActiveTab(1)"
                  id="classesSection"
                  :checked="currentTab == 1 ? 'checked' : ''"
                />
                <label
                  for="classesSection"
                  style="
                    border: 1px solid rgb(192, 196, 233);
                    border-radius: 15px 15px 0px 0px;
                  "
                  >New Application [{{ newApplicationList.length }}]</label
                >
                <div class="tab">
                  <div class="tbllist mt-1">
                    <div class="restable">
                      <div class="resrow resheader">
                        <!-- <div class="cell">S.No.</div> -->
                        <div class="cell">Candidate Name</div>
                        <div class="cell">Parent Name</div>
                        <div class="cell">Parent Email</div>
                        <div class="cell">Class Applied</div>
                        <div class="cell">Status</div>
                        <div class="cell">Action</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(data, index) in newApplicationList"
                        :key="index"
                      >
                        <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                        <div class="cell" data-title="Candidate Name">
                          {{ data.firstName + " " + data.lastName }}
                        </div>
                        <div class="cell" data-title="Parent Name">
                          {{
                            data.parent
                              ? data.parent.firstName
                              : ""
                          }}
                        </div>
                        <div class="cell" data-title="Email">
                          {{
                            data.parent ? data.parent.email : ""
                          }}
                        </div>
                        <div class="cell" data-title="Enrollment Status">
                          {{ data.className }}
                        </div>
                        <div class="cell" data-title="Class Applied">
                          {{ data.admissionStatus }}
                        </div>

                        <div class="cell" data-title="Action">
                          <b-dropdown
                            id="dropdown-1"
                            text=""
                            right
                            style="margin-right: 3px"
                          >
                            <b-dropdown-item :disabled="data.admissionStatus != 'Submitted'"
                              @click.prevent="updateAction(data._id, 'Accept')"
                              >Accept</b-dropdown-item
                            >
                            <b-dropdown-item :disabled="data.admissionStatus != 'Submitted'"
                              @click.prevent="updateAction(data._id, 'Reject')"
                              >Reject</b-dropdown-item
                            >
                            
                            <b-dropdown-item @click.prevent="editAction(data._id,index,'newApplicationList')">Edit</b-dropdown-item>
                            <b-dropdown-item @click.prevent="viewAction(data._id,index,'newApplicationList')">View</b-dropdown-item>

                            <b-dropdown-item  @click.prevent="confirmDelete(data._id)"  >Delete</b-dropdown-item>

                            <!-- <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="updateAction(data._id, 'Approved')"
                        >Approve</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="
                          updateCancelAction(data._id, 'Rejected', data)
                        "
                        >Reject</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="
                          updateCancelAction(data._id, 'Canceled', data)
                        "
                        >Cancel</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Confirm' &&
                          data.school.version != 'paidVersion'
                        "
                        @click.prevent="enrollSchoolAction(index)"
                        >Proceed</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Confirm' &&
                          data.school.version == 'paidVersion'
                        "
                        @click.prevent="assignSection(index)"
                        >Assign Class And Section</b-dropdown-item
                      > -->
                          </b-dropdown>

                          <!-- <button
                      :disabled="data.admissionStatus == 'Active'"
                      class="btn round orange tooltipt"
                      @click.prevent="openEditPopUp(index)"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
                    <button
                      class="btn round orange tooltipt"
                      @click.prevent="openViewPopUp(index)"
                    >
                      <i class="far fa-eye"></i
                      ><span class="tooltiptext">View</span>
                    </button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  :checked="currentTab == 2 ? 'checked' : ''"
                  @click="setActiveTab(2)"
                  id="assignedClass"
                />
                <label
                  for="assignedClass"
                  style="
                    border: 1px solid rgb(192, 196, 233);
                    border-radius: 15px 15px 0px 0px;
                  "
                  >Application Status [{{ acceptedApplicationList.length + rejectedApplicationList.length }}]</label
                >
                <div class="tab">
                  <div>
                  <div class="subtabs" style="height: 2%; background: none">
                    <input
                      type="radio"
                      @click="setActiveSubTab(2.1)"
                      :checked="currentSubTab == 2.1 ? 'checked' : ''"

                      name="subtabs"
                      id="AcademicsTab"
                      
                    />
                    <label for="AcademicsTab">Accepted [{{ acceptedApplicationList.length }}]</label>
                    <div class="tab">
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3">
                          <div class="tbllist mt-1">
                            <div class="restable">
                              <div class="resrow resheader">
                                <!-- <div class="cell">S.No.</div> -->
                                <div class="cell">Candidate Name</div>
                                <div class="cell">Parent Name</div>
                                <div class="cell">Parent Email</div>
                                <div class="cell">Class Applied</div>
                                <div class="cell">Status</div>
                                <div class="cell">Action</div>
                              </div>
                              <div
                                class="resrow"
                                v-for="(data, index) in acceptedApplicationList"
                                :key="index"
                              >
                                <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                                <div class="cell" data-title="Candidate Name">
                                  {{ data.firstName + " " + data.lastName }}
                                </div>
                                <div class="cell" data-title="Parent Name">
                                  {{
                                    data.parent
                                      ? data.parent.firstName
                                      : ""
                                  }}
                                </div>
                                <div class="cell" data-title="Email">
                                  {{
                                    data.parent
                                      ? data.parent.email
                                      : ""
                                  }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Enrollment Status"
                                >
                                  {{ data.className }}
                                </div>
                                <div class="cell" data-title="Class Applied">
                                  {{ data.admissionStatus }}
                                </div>

                                <div class="cell" data-title="Action">
                                  <b-dropdown
                                    id="dropdown-1"
                                    text=""
                                    right
                                    style="margin-right: 3px"
                                  >
                                    <b-dropdown-item
                                      @click.prevent="openinviatePopUp(index)"
                                      >Schedule for Interview</b-dropdown-item
                                    >

                                    <b-dropdown-item @click.prevent="editAction(data._id,index,'acceptedApplicationList')">Edit</b-dropdown-item>
                                    <b-dropdown-item @click.prevent="viewAction(data._id,index,'acceptedApplicationList')">View</b-dropdown-item>

                                    <b-dropdown-item>Delete</b-dropdown-item>

                                    <!-- <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="updateAction(data._id, 'Approved')"
                        >Approve</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="
                          updateCancelAction(data._id, 'Rejected', data)
                        "
                        >Reject</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="
                          updateCancelAction(data._id, 'Canceled', data)
                        "
                        >Cancel</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Confirm' &&
                          data.school.version != 'paidVersion'
                        "
                        @click.prevent="enrollSchoolAction(index)"
                        >Proceed</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Confirm' &&
                          data.school.version == 'paidVersion'
                        "
                        @click.prevent="assignSection(index)"
                        >Assign Class And Section</b-dropdown-item
                      > -->
                                  </b-dropdown>

                                  <!-- <button
                      :disabled="data.admissionStatus == 'Active'"
                      class="btn round orange tooltipt"
                      @click.prevent="openEditPopUp(index)"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
                    <button
                      class="btn round orange tooltipt"
                      @click.prevent="openViewPopUp(index)"
                    >
                      <i class="far fa-eye"></i
                      ><span class="tooltiptext">View</span>
                    </button> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <input
                      type="radio"
                      name="subtabs"
                      :checked="currentSubTab == 2.2 ? 'checked' : ''"
                      @click="setActiveSubTab(2.2)"
                      id="CurricularActivities"
                    />
                    <label for="CurricularActivities">Rejected [{{ rejectedApplicationList.length }}]</label>
                    <div class="tab">
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3">
                          <div class="tbllist mt-1">
                            <div class="restable">
                              <div class="resrow resheader">
                                <!-- <div class="cell">S.No.</div> -->
                                <div class="cell">Candidate Name</div>
                                <div class="cell">Parent Name</div>
                                <div class="cell">Parent Email</div>
                                <div class="cell">Class Applied</div>
                                <div class="cell">Status</div>
                                <div class="cell">Action</div>
                              </div>
                              <div
                                class="resrow"
                                v-for="(data, index) in rejectedApplicationList"
                                :key="index"
                              >
                                <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                                <div class="cell" data-title="Candidate Name">
                                  {{ data.firstName + " " + data.lastName }}
                                </div>
                                <div class="cell" data-title="Parent Name">
                                  {{
                                    data.parent
                                      ? data.parent.firstName
                                      : ""
                                  }}
                                </div>
                                <div class="cell" data-title="Email">
                                  {{
                                    data.parent
                                      ? data.parent.email
                                      : ""
                                  }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Enrollment Status"
                                >
                                  {{ data.className }}
                                </div>
                                <div class="cell" data-title="Class Applied">
                                  {{ data.admissionStatus }}
                                </div>

                                <div class="cell" data-title="Action">
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                   
                    <b-dropdown-item @click.prevent="editAction(data._id,index,'rejectedApplicationList')">Edit</b-dropdown-item>
                    <b-dropdown-item @click.prevent="actionPreviousStage('Application Accepted',data._id)">Move to Previous Stage</b-dropdown-item>


                    </b-dropdown>

                  
                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <input
                  type="radio"
                  :checked="currentTab == 3 ? 'checked' : ''"
                  @click="setActiveTab(3)"
                  name="tabs"
                  id="subcoursetab"
                />
                <label
                  for="subcoursetab"
                  style="
                    border: 1px solid rgb(192, 196, 233);
                    border-radius: 15px 15px 0px 0px;
                  "
                  >Interview Status [{{ scheduledApplicationList.length + rejectInInterviewApplicationList.length + waitingListApplicationList.length }}]
                </label>
                <div class="tab">
                  <div class="tabs" style="height: 2%; background: none">
                    <input type="radio" id="asdcerererevcff"    @click="setActiveSubTab(3.1)" :checked="currentSubTab == 3.1 ? 'checked' : ''" />
                    <label for="asdcerererevcff">Scheduled [{{ scheduledApplicationList.length }}]</label>
                    <div class="tab">
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3">
                          <div class="tbllist mt-1">
                            <div class="restable">
                              <div class="resrow resheader">
                                <!-- <div class="cell">S.No.</div> -->
                                <div class="cell">Candidate Name</div>
                                <div class="cell">Parent Name</div>
                                <div class="cell">Parent Email</div>
                                <div class="cell">Class Applied</div>
                                <div class="cell">Status</div>
                                <div class="cell">Action</div>
                              </div>
                              <div
                                class="resrow"
                                v-for="(
                                  data, index
                                ) in scheduledApplicationList"
                                :key="index"
                              >
                                <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                                <div class="cell" data-title="Candidate Name">
                                  {{ data.firstName + " " + data.lastName }}
                                </div>
                                <div class="cell" data-title="Parent Name">
                                  {{
                                    data.parent
                                      ? data.parent.firstName
                                      : ""
                                  }}
                                </div>
                                <div class="cell" data-title="Email">
                                  {{
                                    data.parent
                                      ? data.parent.email
                                      : ""
                                  }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Enrollment Status"
                                >
                                  {{ data.className }}
                                </div>
                                <div class="cell" data-title="Class Applied">
                                  {{ data.admissionStatus }}
                                </div>

                                <div class="cell" data-title="Action">
                                  <b-dropdown
                                    id="dropdown-1"
                                    text=""
                                    right
                                    style="margin-right: 3px"
                                  >
                                  <b-dropdown-item @click.prevent="editAction(data._id,index,'scheduledApplicationList')">Edit</b-dropdown-item>
                                  <b-dropdown-item @click.prevent="interviewResudle(index,'scheduledApplicationList')">Interview Reschedule</b-dropdown-item>

                                    <b-dropdown-item
                                      @click.prevent="
                                        changeStatus(
                                          data._id,
                                          'Admission Approved'
                                        )
                                      "
                                      >Approve Admission</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                      @click.prevent="
                                        changeStatus(data._id, 'waitlist')
                                      "
                                      >Add to waitlist</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                      @click.prevent="
                                        changeStatus(
                                          data._id,
                                          'Reject in Interview'
                                        )
                                      "
                                      >Reject in Interview</b-dropdown-item
                                    >
                                  </b-dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input type="radio" id="asdcvggggcff" @click="setActiveSubTab(3.2)" :checked="currentSubTab == 3.2 ? 'checked' : ''"/>
                    <label for="asdcvggggcff">Rejected [{{ rejectInInterviewApplicationList.length }}]</label>
                    <div class="tab">
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3">
                          <div class="tbllist mt-1">
                            <div class="restable">
                              <div class="resrow resheader">
                                <!-- <div class="cell">S.No.</div> -->
                                <div class="cell">Candidate Name</div>
                                <div class="cell">Parent Name</div>
                                <div class="cell">Parent Email</div>
                                <div class="cell">Class Applied</div>
                                <div class="cell">Status</div>
                                <div class="cell">Action</div>
                              </div>
                              <div
                                class="resrow"
                                v-for="(
                                  data, index
                                ) in rejectInInterviewApplicationList"
                                :key="index"
                              >
                                <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                                <div class="cell" data-title="Candidate Name">
                                  {{ data.firstName + " " + data.lastName }}
                                </div>
                                <div class="cell" data-title="Parent Name">
                                  {{
                                    data.parent
                                      ? data.parent.firstName
                                      : ""
                                  }}
                                </div>
                                <div class="cell" data-title="Email">
                                  {{
                                    data.parent
                                      ? data.parent.email
                                      : ""
                                  }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Enrollment Status"
                                >
                                  {{ data.className }}
                                </div>
                                <div class="cell" data-title="Class Applied">
                                  {{ data.admissionStatus }}
                                </div>

                                <div class="cell" data-title="Action">
                                  <b-dropdown
                                    id="dropdown-1"
                                    text=""
                                    right
                                    style="margin-right: 3px"
                                  >
                                  <b-dropdown-item @click.prevent="editAction(data._id,index,'rejectInInterviewApplicationList')">Edit</b-dropdown-item>
                                  <b-dropdown-item @click.prevent="interviewResudle(index,'rejectInInterviewApplicationList')">Interview Reschedule</b-dropdown-item>

                                    <!-- <b-dropdown-item   @click.prevent="openinviatePopUp(index)">Schedule for Interview</b-dropdown-item>
                      <b-dropdown-item>Edit</b-dropdown-item>
                      <b-dropdown-item>Delete</b-dropdown-item> -->
                                  </b-dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <input type="radio" name="subtfdgfgfabs" id="dfdddfd" @click="setActiveSubTab(3.3)" :checked="currentSubTab == 3.3 ? 'checked' : ''"/>
                    <label for="dfdddfd">Waiting List [{{ waitingListApplicationList.length }}]</label>
                    <div class="tab">
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3">
                          <div class="tbllist mt-1">
                            <div class="restable">
                              <div class="resrow resheader">
                                <!-- <div class="cell">S.No.</div> -->
                                <div class="cell">Candidate Name</div>
                                <div class="cell">Parent Name</div>
                                <div class="cell">Parent Email</div>
                                <div class="cell">Class Applied</div>
                                <div class="cell">Status</div>
                                <div class="cell">Action</div>
                              </div>
                              <div
                                class="resrow"
                                v-for="(
                                  data, index
                                ) in waitingListApplicationList"
                                :key="index"
                              >
                                <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                                <div class="cell" data-title="Candidate Name">
                                  {{ data.firstName + " " + data.lastName }}
                                </div>
                                <div class="cell" data-title="Parent Name">
                                  {{
                                    data.parent
                                      ? data.parent.firstName
                                      : ""
                                  }}
                                </div>
                                <div class="cell" data-title="Email">
                                  {{
                                    data.parent
                                      ? data.parent.email
                                      : ""
                                  }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Enrollment Status"
                                >
                                  {{ data.className }}
                                </div>
                                <div class="cell" data-title="Class Applied">
                                  {{ data.admissionStatus }}
                                </div>
                                <div class="cell" data-title="Action">
                                  <b-dropdown
                                    id="dropdown-1"
                                    text=""
                                    right
                                    style="margin-right: 3px"
                                  >
                                  <b-dropdown-item @click.prevent="editAction(data._id,index,'waitingListApplicationList')">Edit</b-dropdown-item>

                                  <b-dropdown-item @click.prevent="interviewResudle(index,'waitingListApplicationList')">Interview Reschedule</b-dropdown-item>

                                  </b-dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  :checked="currentTab == 4 ? 'checked' : ''"
                  @click="setActiveTab(4)"
                  name="tabs"
                  id="statusTab"
                />
                <label
                  for="statusTab"
                  style="
                    border: 1px solid rgb(192, 196, 233);
                    border-radius: 15px 15px 0px 0px;
                  "
                  >Payment Status [{{  admissionApprovedApplicationList.length }}]</label
                >
                <div class="tab">
                  <div class="tbllist mt-1">
                    <div class="restable">
                      <div class="resrow resheader">
                        <!-- <div class="cell">S.No.</div> -->
                        <div class="cell">Candidate Name</div>
                        <div class="cell">Parent Name</div>
                        <div class="cell">Parent Email</div>
                        <div class="cell">Class Applied</div>
                        <div class="cell">Status</div>
                        <div class="cell">Action</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(
                          data, index
                        ) in admissionApprovedApplicationList"
                        :key="index"
                      >
                        <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                        <div class="cell" data-title="Candidate Name">
                          {{ data.firstName + " " + data.lastName }}
                        </div>
                        <div class="cell" data-title="Parent Name">
                          {{ data.parent ? data.parent.firstName : "" }}
                        </div>
                        <div class="cell" data-title="Email">
                          {{ data.parent ? data.parent.email : "" }}
                        </div>
                        <div class="cell" data-title="Enrollment Status">
                          {{ data.className }}
                        </div>
                        <div class="cell" data-title="Class Applied">
                          {{ data.admissionStatus }}
                        </div>

                        <div class="cell" data-title="Action">
                          <b-dropdown
                            id="dropdown-1"
                            text=""
                            right
                            style="margin-right: 3px"
                          >
                          <b-dropdown-item @click.prevent="viewAction(data._id,index,'admissionApprovedApplicationList')">View</b-dropdown-item>

                            <b-dropdown-item
                              @click.prevent="
                                changeStatus(data._id, 'Payment Cleared')
                              "
                              >Confirm Payment Clearance</b-dropdown-item
                            >
                            <b-dropdown-item
                              @click.prevent="
                                changeStatus(data._id, 'Admission Rejected')
                              "
                              >Reject Admission</b-dropdown-item
                            >
                            <b-dropdown-item>Payment Reminder</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  :checked="currentTab == 5 ? 'checked' : ''"
                  @click="setActiveTab(5)"
                  name="tabs"
                  id="admissionStatusTab"
                />
                <label
                  for="admissionStatusTab"
                  style="
                    border: 1px solid rgb(192, 196, 233);
                    border-radius: 15px 15px 0px 0px;
                  "
                  >Admission Status [{{ accountClearedApplicationList.length + rejectedApplicationList.length }}]
                </label>
                <div class="tab">
                  <div class="subtabs" style="height: 2%; background: none">
                    <input type="radio" id="accptid" @click="setActiveSubTab(5.1)" :checked="currentSubTab == 5.1 ? 'checked' : ''" />
                    <label for="accptid">Accepted [{{ accountClearedApplicationList.length }}]</label>
                    <div class="tab">
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3">
                          <div class="tbllist mt-1">
                            <div class="restable">
                              <div class="resrow resheader">
                                <!-- <div class="cell">S.No.</div> -->
                                <div class="cell">Candidate Name</div>
                                <div class="cell">Parent Name</div>
                                <div class="cell">Parent Email</div>
                                <div class="cell">Class Applied</div>
                                <div class="cell">Status</div>
                                <div class="cell">Action</div>
                              </div>
                              <div
                                class="resrow"
                                v-for="(data, index) in accountClearedApplicationList"
                                :key="index"
                              >
                                <div class="cell" data-title="Candidate Name">
                                  {{ data.firstName + " " + data.lastName }}
                                </div>
                                <div class="cell" data-title="Parent Name">
                                  {{
                                    data.parent
                                      ? data.parent.firstName
                                      : ""
                                  }}
                                </div>
                                <div class="cell" data-title="Email">
                                  {{
                                    data.parent
                                      ? data.parent.email
                                      : ""
                                  }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Enrollment Status"
                                >
                                  {{ data.className }}
                                </div>
                                <div class="cell" data-title="Class Applied">
                                  {{ data.admissionStatus }}
                                </div>

                                <div class="cell" data-title="Action">
                                  <b-dropdown
                                    id="dropdown-1"
                                    text=""
                                    right
                                    style="margin-right: 3px"
                                  >
                                    <!-- <b-dropdown-item
                                     
                                      >Add to Classroom</b-dropdown-item
                                    > -->
                                    <b-dropdown-item @click.prevent="viewAction(data._id,index,'accountClearedApplicationList')">View</b-dropdown-item>

                                    <b-dropdown-item
                                        v-if="data.admissionStatus == 'Payment Cleared' && data.school.version == 'paidVersion'"
                                        @click.prevent="assignSection(index)"
                                        >Assign Class And Section</b-dropdown-item
                                      >
                                    <!-- <b-dropdown-item>Send Invite</b-dropdown-item> -->
                                    <!-- <b-dropdown-item>Delete</b-dropdown-item> -->
                                  </b-dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <input type="radio" name="subtabs" id="rehid" @click="setActiveSubTab(5.2)" :checked="currentSubTab == 5.2 ? 'checked' : ''" />
                    <label for="rehid">Rejected [{{  rejectedApplicationList.length }}]</label>
                    <div class="tab">
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3">
                          <div class="tbllist mt-1">
                            <div class="restable">
                              <div class="resrow resheader">
                                <!-- <div class="cell">S.No.</div> -->
                                <div class="cell">Candidate Name</div>
                                <div class="cell">Parent Name</div>
                                <div class="cell">Parent Email</div>
                                <div class="cell">Class Applied</div>
                                <div class="cell">Status</div>
                                <!-- <div class="cell">Action</div> -->
                              </div>
                              <div
                                class="resrow"
                                v-for="(data, index) in rejectedApplicationList"
                                :key="index"
                              >
                                <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                                <div class="cell" data-title="Candidate Name">
                                  {{ data.firstName + " " + data.lastName }}
                                </div>
                                <div class="cell" data-title="Parent Name">
                                  {{
                                    data.parent
                                      ? data.parent.firstName
                                      : ""
                                  }}
                                </div>
                                <div class="cell" data-title="Email">
                                  {{
                                    data.parent
                                      ? data.parent.email
                                      : ""
                                  }}
                                </div>
                                <div
                                  class="cell"
                                  data-title="Enrollment Status"
                                >
                                  {{ data.className }}
                                </div>
                                <div class="cell" data-title="Class Applied">
                                  {{ data.admissionStatus }}
                                </div>

                                <!-- <div class="cell" data-title="Action">
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item  @click.prevent="updateAction(data._id, 'Accept')">Accept</b-dropdown-item>
                      <b-dropdown-item  @click.prevent="updateAction(data._id, 'Reject')">Reject</b-dropdown-item>
                      <b-dropdown-item>Edit</b-dropdown-item>
                      <b-dropdown-item>Delete</b-dropdown-item>

                    </b-dropdown>

                  
                  </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 6px"
                  ><i class="fas fa-users"></i
                ></span>
                <h1>Candidates Details</h1>
              </div>
              <div style="width: 21%" class="flexitm">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    @change="getSelectRange"
                    v-model="selectAcademicYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
                  <label for="acyearid"
                    >Academic Year <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="mt-2 mr-2">
                <button class="btn gry_bg tooltipt mpopup twoicn" @click="openPoup" >
								<i class="fas fa-user user1"></i>
									<p class="user2"><i class="fas fa-plus"></i></p>
								<span class="tooltiptext">Create Online Admission</span>									
							</button>
              </div>
            </div>
            <div class="tbllist mt-1">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Candidate Name</div>
                  <div class="cell">Parent Name</div>
                  <div class="cell">Parent Email</div>
                  <div class="cell">Class Applied</div>
                  <div class="cell"> Status</div>
                  <div class="cell">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in onlineAdmissionList"
                  :key="index"
                >
                  <div class="cell" data-title="Candidate Name">
                    {{ data.firstName + " " + data.lastName }}
                  </div>
                  <div class="cell" data-title="Parent Name">
                    {{ data.parent ? data.parent.firstName : "" }}
                  </div>
                  <div class="cell" data-title="Email">
                    {{ data.parent ? data.parent.email : "" }}
                  </div>
                  <div class="cell" data-title="Enrollment Status">
                    {{ data.className }}
                  </div>
                  <div class="cell" data-title="Class Applied">
                    {{ data.admissionStatus }}
                  </div>

                 
                  <div class="cell" data-title="Action">
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item
                        :disabled="
                          data.admissionStatus == 'Active' ||
                          data.admissionStatus == 'Confirm'
                        "
                        @click.prevent="openEditInfoPopUp(index)"
                        >Need More Info</b-dropdown-item
                      >
                      <b-dropdown-item
                        :disabled="
                          data.admissionStatus == 'Active' ||
                          data.admissionStatus == 'Confirm'
                        "
                        @click.prevent="openEditDocumentPopUp(index)"
                        >Documents Missing</b-dropdown-item
                      >

                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="updateAction(data._id, 'Approved')"
                        >Approve</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="
                          updateCancelAction(data._id, 'Rejected', data)
                        "
                        >Reject</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Submitted' ||
                          data.admissionStatus == 'Assessment scheduled'
                        "
                        @click.prevent="
                          updateCancelAction(data._id, 'Canceled', data)
                        "
                        >Cancel</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Confirm' &&
                          data.school.version != 'paidVersion'
                        "
                        @click.prevent="enrollSchoolAction(index)"
                        >Proceed</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          data.admissionStatus == 'Confirm' &&
                          data.school.version == 'paidVersion'
                        "
                        @click.prevent="assignSection(index)"
                        >Assign Class And Section</b-dropdown-item
                      >
                    </b-dropdown>

                    <button
                      :disabled="data.admissionStatus == 'Active'"
                      class="btn round orange tooltipt"
                      @click.prevent="openEditPopUp(index)"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
                    <button
                      class="btn round orange tooltipt"
                      @click.prevent="openViewPopUp(index)"
                    >
                      <i class="far fa-eye"></i
                      ><span class="tooltiptext">View</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-4" v-if="onlineAdmissionList.length > 0">
              <div class="pagination" id="pagination">
                <a
                  :style="
                    currentPageNumber === 1 ? 'pointer-events: none;' : ''
                  "
                  @click="goToPreviousPage"
                  >&laquo;</a
                >
                <a
                  v-for="(item, index) in lastPageNumber"
                  :key="index"
                  v-on:click="selected = item"
                  v-bind:class="{ active: selected == item }"
                  @click="navToSelectedPage(item)"
                  >{{ item }}</a
                >
                <a
                  :style="
                    currentPageNumber === lastPageNumber
                      ? 'pointer-events: none;'
                      : ''
                  "
                  @click="goToNextPage"
                  >&raquo;</a
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div>
      <b-modal id="editstudpop" class="modal">
        <button type="button" class="close" @click.prevent="closePoup">
          ×
        </button>

        <div
          class="contact-form"
          v-if="studentObj && studentObj.parent && showAssign"
        >
          <h2>Student Information</h2>
          <br />
          <div style="height: 350px; overflow-y: auto">
            <form
              data-vv-scope="studentvalidate"
              id="stuprofile"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      disabled
                      name="studentFirstName"
                      v-model="studentObj.firstName"
                      class="form-control form-input"
                      id="stdFirstName"
                      placeholder="First Name"
                    />

                    <label for="stdFirstName"
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      disabled
                      name="studentLastName"
                      v-model="studentObj.lastName"
                      class="form-control form-input"
                      id="stdLastName"
                      placeholder="Last Name"
                    />

                    <label for="stdLastName"
                      >Last Name<span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      disabled
                      name="stdEmail"
                      v-model="studentObj.email"
                      class="form-control form-input"
                      id="stdEmail"
                      placeholder="Secondary Email"
                    />
                    <label for="stdEmail">Secondary Email</label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      disabled
                      name="stdEmail"
                      class="form-control form-input"
                      id="stdEmail"
                      v-model="studentObj.admissionStatus"
                      placeholder="Secondary Email"
                    />
                    <label for="enrollmentStatus"
                      >Enrollment Status <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <!-- max="9999-12-31" -->
                  <span class="has-float-label">
                    <input
                      type="date"
                      disabled
                      onkeydown="return false"
                      name="DOB"
                      v-model="studentObj.dob"
                      class="form-control form-input"
                      id="stdDob"
                      placeholder="Date of Birth"
                    />

                    <label for="stdDob"
                      >Date of Birth <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      disabled
                      name="Blood Group"
                      class="form-control form-input"
                      id="bloodGroup"
                      v-model="studentObj.bloodGroup"
                    />

                    <label for="bloodGroup"
                      >Blood Group <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      disabled
                      name="gender"
                      class="form-control form-input"
                      id="gender"
                      v-model="studentObj.gender"
                    />

                    <label for="firstName"
                      >Gender <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-6 pr-md-3 mt-3"
                  v-if="studentObj.parent && studentObj.parent.userName"
                >
                  <span class="has-float-label">
                    <input
                      disabled
                      name="gender"
                      class="form-control form-input"
                      id="gender"
                      v-model="studentObj.parent.userName"
                    />

                    <label for="firstName"
                      >UserName <span class="required">*</span></label
                    >
                  </span>
                </div>

                <fieldset class="accordion-group-container">
                  <div>
                    <label
                      style="margin: 0"
                      for="accordiongroup-1"
                      class="blutitle accordion-label"
                    >
                      Parent Details
                    </label>
                    <div class="form-row">
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            disabled
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="studentObj.pointOfContact"
                          />
                          <label for="firstName"
                            >Point of contact
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span
                          class="has-float-label"
                          v-if="studentObj.pointOfContact == 'Father'"
                        >
                          <input
                            type="text"
                            disabled
                            name="fthrname"
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father Name"
                          />

                          <label for="fthrnameid"
                            >Father Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="studentObj.pointOfContact == 'Mother'"
                        >
                          <input
                            type="text"
                            name="Mother"
                            disabled
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="Mother"
                            placeholder="Mother Name"
                          />

                          <label for="fthrnameid"
                            >Mother Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="studentObj.pointOfContact == 'Guardian'"
                        >
                          <input
                            type="text"
                            name="Guardian"
                            disabled
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="Guardian"
                            placeholder="Guardian Name"
                          />

                          <label for="Guardian"
                            >Guardian Name
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <!-- <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="userDetails.occupation"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Occupation"
                          />
                          <label for="occid"
                            >Occupation </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            v-validate="'required'"
                            type="email"
                            name="ptemail"
                            :disabled="editId"
                            v-model="userDetails.email"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                          />
                          <label
                            v-if="errors.first('studentvalidate.ptemail')"
                            class="vi-error"
                            style="top:-1em"
                            >Primary Email Id is required</label
                          >
                      
                          <label for="ptemailid" v-else
                            >Primary Email <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            @input="checkMobile"
                            maxlength="10"
                            v-validate="'required|numeric|min:10|max:10'"
                            name="ptph"
                            v-model="userDetails.phoneNo"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Phone"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.ptph') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.ptph') ? 'Phone number is required and only 10 digits' : 'Phone Number already exist. please use a different one.' }}</label
                          >
                          <label v-else for="ptphid">Phone <span class="required">*</span></label>
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stadr"
                            v-model="userDetails.address"
                            class="form-control form-input"
                            id="stadrid"
                            placeholder="Address Details"
                          />
                          <label for="stadrid"
                            >Address Details</label
                          >
                        </span>
                      </div> -->
                    </div>
                  </div>

                  <div>
                    <label
                      style="margin: 0"
                      for="accordiongroup-2"
                      class="blutitle accordion-label"
                    >
                      Candidate Enrollment Details
                    </label>
                    <div class="form-row">
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            v-validate="'required'"
                            type="text"
                            name="roll No allocated"
                            v-model="studentObj.rollNoAllocated"
                            class="form-control form-input"
                            id="rnaltid"
                            placeholder="Roll No. allocated"
                          />
                          <label
                            v-if="
                              errors.first('studentvalidate.roll No allocated')
                            "
                            class="vi-error"
                            style="top: -1em"
                            >Roll no allocated is required</label
                          >

                          <label for="rnaltid" v-else
                            >Roll No. allocated
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                        <span class="has-float-label">
                          <select
                            name="AcademicYear"
                            disabled
                            class="form-control form-input"
                            id="deptlistid"
                            v-model="studentObj.academicYear"
                          >
                            <option :value="null">--Select--</option>
                            <option
                              v-for="(item, index) in academicYearList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.from }} - {{ item.to }}
                            </option>
                          </select>
                          <label for="acyearid"
                            >Academic Year
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            name="Class"
                            disabled
                            class="form-control form-input"
                            v-model="studentObj.className"
                            type="text"
                            id="rnaltid"
                            placeholder="Roll No. allocated"
                          />

                          <label for="clname"
                            >Class Name<span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="Section"
                            class="form-control form-input"
                            id="deptlistid"
                            v-model="studentObj.classSection"
                          >
                            <option :value="null">--Select--</option>
                            <option
                              v-for="(item, index) in sectionList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.Section')"
                            class="vi-error"
                            style="top: -1em"
                            >Section is required</label
                          >

                          <label for="clname" v-else
                            >Section<span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <!-- max="9999-12-31" -->
                        <span class="has-float-label">
                          <input
                            v-validate="'required'"
                            type="date"
                            :min="minPlanDate"
                            :max="maxPlanDate"
                            onkeydown="return false"
                            name="DOJ"
                            v-model="studentObj.doj"
                            class="form-control form-input"
                            id="DOJ"
                            placeholder="Date of Joining"
                          />
                          <label
                            v-if="errors.first('studentvalidate.DOJ')"
                            class="vi-error"
                            style="top: -1em"
                            >Date of Joining is required</label
                          >

                          <label for="DOJ" v-else
                            >Date of Joining
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
             
            </form>
          </div>
          <div
              
              >
              
                <div class="text-center dk_iconsml">
                  <button style="width: 15%;"
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="createStudent"
                  >
                    <span>Create Student</span>
                  </button>
                  <button
                    type="button"
                    @click.prevent="closePoup"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="actionPopup" no-close-on-backdrop no-close-on-esc >
        <!-- <div class="modal-content" > -->
        <div class="contact-form" >
          <!-- <span class="close">&times;</span> -->
          <h2>
            {{ updateActionType }}
            Confirmation
          </h2>
          <br />
          Are you sure you want to
          {{ updateActionType }} this record?
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              @click.prevent="actionConfirm"
              type="button"
              class="btn btnsml"
              id="svbtn"
            >
              <span>Confirm</span>
            </button>
            <button
              @click.prevent="closeActionPopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="actionCancelPopup" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close" @click.prevent="closePoup">
          ×
        </button>

        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>
            {{ updateActionType == "Rejected" ? "Reject" : "Cancel" }}
            Confirmation
          </h2>
          <br />
          Are you sure you want to
          {{ updateActionType == "Rejected" ? "Reject" : "Cancel" }} this
          record?
          <div class="form-row">
            <div class="form-group input-group col-md-6 mt-3">
              <span class="has-float-label">
                <textarea
                  name="comment"
                  v-model="actionComments"
                  class="form-control form-input"
                  style="min-height: 60px; resize: none"
                  id="tocmts"
                ></textarea>

                <label for="acyearid"
                  >Comments <span class="required">*</span></label
                >
              </span>
            </div>
          </div>
          <div
            class="pt-3 text-right dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              :disabled="!actionComments"
              @click.prevent="actionCancelConfirm"
              type="button"
              class="btn btnsml"
              id="svbtn"
            >
              <span>Confirm</span>
            </button>
            <button
              @click.prevent="closeCancelActionPopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="addstudpop" class="modal">
        <div class="contact-form">
          <h2>EDIT CANDIDATE</h2>
          <br />
          <div class="tabs" style="height: 2%; background: none">
            <input type="radio" name="tabs" id="classestab" checked="checked" />
            <label for="classestab">Details</label>
            <div class="tab">
              <div style="height: 350px; overflow-y: auto">
                <form
                  data-vv-scope="studentvalidate"
                  id="stuprofile"
                  accept-charset="utf-8"
                >
                  <div class="form-row">
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          disabled
                          name="studentFirstName"
                          v-model="studentObj.firstName"
                          class="form-control form-input"
                          id="stdFirstName"
                        />

                        <label for="stdFirstName"
                          >First Name <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          disabled
                          name="studentLastName"
                          v-model="studentObj.lastName"
                          class="form-control form-input"
                          id="stdLastName"
                        />

                        <label for="stdLastName"
                          >Last Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          disabled
                          name="stdEmail"
                          v-model="studentObj.email"
                          class="form-control form-input"
                          id="stdEmail"
                          placeholder="Secondary Email"
                        />
                        <label for="stdEmail">Secondary Email</label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          disabled
                          name="status"
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="studentObj.admissionStatus"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in statusList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                        <!--                     
                   <input
                    type="text"
                    disabled
                    name="stdEmail"
                    class="form-control form-input"
                    id="stdEmail"
                    v-model="studentObj.admissionStatus"
                    placeholder="Secondary Email"
                  /> -->
                        <label for="enrollmentStatus"
                          >Enrollment Status
                          <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <!-- max="9999-12-31" -->
                      <span class="has-float-label">
                        <input
                          type="date"
                          disabled
                          onkeydown="return false"
                          name="DOB"
                          v-model="studentObj.dob"
                          class="form-control form-input"
                          id="stdDob"
                          placeholder="Date of Birth"
                        />

                        <label for="stdDob"
                          >Date of Birth <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          disabled
                          name="Blood Group"
                          class="form-control form-input"
                          id="bloodGroup"
                          v-model="studentObj.bloodGroup"
                        />

                        <label for="bloodGroup"
                          >Blood Group <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span
                        class="has-float-label"
                        style="justify-content: normal"
                      >
                        <input
                          disabled
                          name="gender"
                          class="form-control form-input"
                          id="gender"
                          v-model="studentObj.gender"
                        />

                        <label for="firstName"
                          >Gender <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <textarea
                          class="form-control web-form-input"
                          style="min-height: 135px; resize: none"
                          id="message"
                          placeholder="Message"
                          v-model="studentObj.comments"
                        ></textarea>

                        <label for="firstName">Comments</label>
                      </span>
                    </div>
                    <fieldset class="accordion-group-container">
                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-1"
                          class="blutitle accordion-label"
                        >
                          Parent Details
                        </label>
                        <div class="form-row">
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                disabled
                                name="pointOfContact"
                                class="form-control form-input"
                                id="pointOfContact"
                                v-model="studentObj.pointOfContact"
                              />
                              <label for="firstName"
                                >Point of contact
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>

                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span
                              class="has-float-label"
                              v-if="studentObj.pointOfContact == 'Father'"
                            >
                              <input
                                type="text"
                                disabled
                                name="fthrname"
                                v-model="studentObj.parent.firstName"
                                class="form-control form-input"
                                id="fthrnameid"
                                placeholder="Father Name"
                              />

                              <label for="fthrnameid"
                                >Father Name
                                <span class="required">*</span></label
                              >
                            </span>
                            <span
                              class="has-float-label"
                              v-if="studentObj.pointOfContact == 'Mother'"
                            >
                              <input
                                type="text"
                                name="Mother"
                                disabled
                                v-model="studentObj.parent.firstName"
                                class="form-control form-input"
                                id="Mother"
                                placeholder="Mother Name"
                              />

                              <label for="fthrnameid"
                                >Mother Name
                                <span class="required">*</span></label
                              >
                            </span>
                            <span
                              class="has-float-label"
                              v-if="studentObj.pointOfContact == 'Guardian'"
                            >
                              <input
                                type="text"
                                name="Guardian"
                                disabled
                                v-model="studentObj.parent.firstName"
                                class="form-control form-input"
                                id="Guardian"
                                placeholder="Guardian Name"
                              />

                              <label for="Guardian"
                                >Guardian Name
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-2"
                          class="blutitle accordion-label"
                        >
                          Candidate Enrollment Details
                        </label>
                        <div class="form-row">
                          <div
                            class="form-group input-group col-md-6 mt-3 pr-md-3"
                          >
                            <span class="has-float-label">
                              <select
                                name="AcademicYear"
                                disabled
                                class="form-control form-input"
                                id="deptlistid"
                                v-model="studentObj.academicYear"
                              >
                                <option :value="null">--Select--</option>
                                <option
                                  v-for="(item, index) in academicYearList"
                                  :key="index"
                                  :value="item._id"
                                >
                                  {{ item.from }} - {{ item.to }}
                                </option>
                              </select>
                              <label for="acyearid"
                                >Academic Year
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>

                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                name="Class"
                                disabled
                                class="form-control form-input"
                                v-model="studentObj.className"
                                type="text"
                                id="rnaltid"
                                placeholder="Roll No. allocated"
                              />

                              <label for="clname"
                                >Class Name<span class="required"
                                  >*</span
                                ></label
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div
                    class="dispflex mt-3 pt-3"
                    style="border-top: 1px solid #e5e5e5"
                  >
                    <div class="dk_icon"></div>
                    <div class="text-right dk_iconsml">
                      <button
                        type="button"
                        class="btn btnsml"
                        id="svbtn"
                        @click.prevent="updateDetails"
                      >
                        <span>Update</span>
                      </button>
                      <button
                        type="button"
                        @click.prevent="closeUpdatePoup"
                        class="btn btncl clsmdl"
                        id="clbtn"
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <input type="radio" name="tabs" id="documenttab" />
            <label for="documenttab">Documents</label>
            <div class="tab">
              <div
                class="form-group row"
                v-for="(data, index) in studentObj.eligibilityCriteriaDocuments"
                :key="index"
              >
                <div class="col-1">
                  <div class="radios">
                    <div class="row">
                      <div class="form-check col-3">
                        <input
                          class="styled-checkbox"
                          id="NIC"
                          type="checkbox"
                          :checked="data.path"
                          disabled
                        />
                        <label class="abs-position" for="NIC"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label>
                    <span>{{ data.name }} <span class="required">*</span></span>
                  </label>
                </div>
                <div class="col-4">
                  <button
                    class="btn btn-doc btn-sm"
                    style="background-color: darkseagreen"
                    @click.prevent="preViewDocument(data.path)"
                    v-if="data.path"
                  >
                    View
                  </button>
                  <span v-else style="color: red"> * Document Missing </span>
                </div>
                <div class="col-2">
                  <button
                    class="btn btn-doc btn-sm"
                    style="background-color: gray"
                    @click.prevent="removeDocument(index)"
                    v-if="!data.path"
                  >
                    Remove
                  </button>
                </div>
              </div>
              <div
                class="dispflex mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <div class="dk_icon"></div>
                <div class="text-right dk_iconsml">
                  <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="addMoreDocumentsPopUp"
                  >
                    <span>Add</span>
                  </button>
                  <button
                    type="button"
                    @click.prevent="closeUpdatePoup"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                    <span>Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="viewstudpop" class="modal">
        <div class="contact-form">
          <h2>EDIT CANDIDATE</h2>
          <br />
          <div class="tabs" style="height: 2%; background: none">
            <input type="radio" name="tabs" id="classestab" checked="checked" />
            <label for="classestab">Details</label>
            <div class="tab">
              <div style="height: 350px; overflow-y: auto">
                <form
                  data-vv-scope="studentvalidate"
                  id="stuprofile"
                  accept-charset="utf-8"
                >
                  <div class="form-row">
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          disabled
                          name="studentFirstName"
                          v-model="studentObj.firstName"
                          class="form-control form-input"
                          id="stdFirstName"
                        />

                        <label for="stdFirstName"
                          >First Name <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          disabled
                          name="studentLastName"
                          v-model="studentObj.lastName"
                          class="form-control form-input"
                          id="stdLastName"
                        />

                        <label for="stdLastName"
                          >Last Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          disabled
                          name="stdEmail"
                          v-model="studentObj.email"
                          class="form-control form-input"
                          id="stdEmail"
                          placeholder="Secondary Email"
                        />
                        <label for="stdEmail">Secondary Email</label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          disabled
                          name="status"
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="studentObj.admissionStatus"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in statusList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>

                        <label for="enrollmentStatus"
                          >Enrollment Status
                          <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          type="date"
                          disabled
                          onkeydown="return false"
                          name="DOB"
                          v-model="studentObj.dob"
                          class="form-control form-input"
                          id="stdDob"
                          placeholder="Date of Birth"
                        />

                        <label for="stdDob"
                          >Date of Birth <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          disabled
                          name="Blood Group"
                          class="form-control form-input"
                          id="bloodGroup"
                          v-model="studentObj.bloodGroup"
                        />

                        <label for="bloodGroup"
                          >Blood Group <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span
                        class="has-float-label"
                        style="justify-content: normal"
                      >
                        <input
                          disabled
                          name="gender"
                          class="form-control form-input"
                          id="gender"
                          v-model="studentObj.gender"
                        />

                        <label for="firstName"
                          >Gender <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <textarea
                          class="form-control web-form-input"
                          style="min-height: 135px; resize: none"
                          id="message"
                          placeholder="Message"
                          v-model="studentObj.comments"
                        ></textarea>

                        <label for="firstName">Comments</label>
                      </span>
                    </div>
                    <fieldset class="accordion-group-container">
                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-1"
                          class="blutitle accordion-label"
                        >
                          Parent Details
                        </label>
                        <div class="form-row">
                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                disabled
                                name="pointOfContact"
                                class="form-control form-input"
                                id="pointOfContact"
                                v-model="studentObj.pointOfContact"
                              />
                              <label for="firstName"
                                >Point of contact
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>

                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span
                              class="has-float-label"
                              v-if="studentObj.pointOfContact == 'Father'"
                            >
                              <input
                                type="text"
                                disabled
                                name="fthrname"
                                v-model="studentObj.parent.firstName"
                                class="form-control form-input"
                                id="fthrnameid"
                                placeholder="Father Name"
                              />

                              <label for="fthrnameid"
                                >Father Name
                                <span class="required">*</span></label
                              >
                            </span>
                            <span
                              class="has-float-label"
                              v-if="studentObj.pointOfContact == 'Mother'"
                            >
                              <input
                                type="text"
                                name="Mother"
                                disabled
                                v-model="studentObj.parent.firstName"
                                class="form-control form-input"
                                id="Mother"
                                placeholder="Mother Name"
                              />

                              <label for="fthrnameid"
                                >Mother Name
                                <span class="required">*</span></label
                              >
                            </span>
                            <span
                              class="has-float-label"
                              v-if="studentObj.pointOfContact == 'Guardian'"
                            >
                              <input
                                type="text"
                                name="Guardian"
                                disabled
                                v-model="studentObj.parent.firstName"
                                class="form-control form-input"
                                id="Guardian"
                                placeholder="Guardian Name"
                              />

                              <label for="Guardian"
                                >Guardian Name
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <label
                          style="margin: 0"
                          for="accordiongroup-2"
                          class="blutitle accordion-label"
                        >
                          Candidate Enrollment Details
                        </label>
                        <div class="form-row">
                          <div
                            class="form-group input-group col-md-6 mt-3 pr-md-3"
                          >
                            <span class="has-float-label">
                              <select
                                name="AcademicYear"
                                disabled
                                class="form-control form-input"
                                id="deptlistid"
                                v-model="studentObj.academicYear"
                              >
                                <option :value="null">--Select--</option>
                                <option
                                  v-for="(item, index) in academicYearList"
                                  :key="index"
                                  :value="item._id"
                                >
                                  {{ item.from }} - {{ item.to }}
                                </option>
                              </select>
                              <label for="acyearid"
                                >Academic Year
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>

                          <div
                            class="form-group input-group col-md-6 pr-md-3 mt-3"
                          >
                            <span class="has-float-label">
                              <input
                                name="Class"
                                disabled
                                class="form-control form-input"
                                v-model="studentObj.className"
                                type="text"
                                id="rnaltid"
                                placeholder="Roll No. allocated"
                              />

                              <label for="clname"
                                >Class Name<span class="required"
                                  >*</span
                                ></label
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div
                    class="dispflex mt-3 pt-3"
                    style="border-top: 1px solid #e5e5e5"
                  >
                    <div class="dk_icon"></div>
                    <div class="text-right dk_iconsml">
                      <button
                        type="button"
                        @click.prevent="closeViewPoup"
                        class="btn btncl clsmdl"
                        id="clbtn"
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <input type="radio" name="tabs" id="documenttab" />
            <label for="documenttab">Documents</label>
            <div class="tab">
              <div
                class="form-group row"
                v-for="(data, index) in studentObj.eligibilityCriteriaDocuments"
                :key="index"
              >
                <div class="col-1">
                  <div class="radios">
                    <div class="row">
                      <div class="form-check col-3">
                        <input
                          class="styled-checkbox"
                          id="NIC"
                          type="checkbox"
                          :checked="data.path"
                          disabled
                        />
                        <label class="abs-position" for="NIC"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label>
                    <span>{{ data.name }} <span class="required">*</span></span>
                  </label>
                </div>
                <div class="col-4">
                  <button
                    class="btn btn-doc btn-sm"
                    style="background-color: darkseagreen"
                    @click.prevent="preViewDocument(data.path)"
                    v-if="data.path"
                  >
                    View
                  </button>
                  <span v-else style="color: red"> * Document Missing </span>
                </div>
                <div class="col-2">
                  <button
                    class="btn btn-doc btn-sm"
                    style="background-color: gray"
                    @click.prevent="removeDocument(index)"
                    v-if="!data.path"
                  >
                    Remove
                  </button>
                </div>
              </div>
              <div
                class="dispflex mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <div class="dk_icon"></div>
                <div class="text-right dk_iconsml">
                  <button
                    type="button"
                    @click.prevent="closeViewPoup"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                    <span>Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="addDocuments" no-close-on-backdrop no-close-on-esc>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Add Additional Documents</h2>
          <br />
          <div class="form-row">
            <div class="form-group input-group col-md-12 mt-md-3">
              <span class="has-float-label">
                <v-select
                  class="form-control form-input"
                  label="label"
                  name="eligibilityCriteriaEdit"
                  multiple
                  v-model="eligibilityCriteria"
                  :options="EligibilityCriteriaList"
                  :reduce="(label) => label.value"
                ></v-select>

                <label for="chsclsid"
                  >Required Proofs <span class="required">*</span></label
                >
              </span>
            </div>
          </div>
          <div
            class="pt-3 text-right dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              @click.prevent="confirmAddDocuments"
              type="button"
              class="btn btnsml"
              id="svbtn"
            >
              <span>Confirm</span>
            </button>
            <button
              @click.prevent="closeAddDocuments"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="needMoreInfoPopUp" no-close-on-backdrop no-close-on-esc>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Comments</h2>
          <br />
          <div class="form-row">
            <div class="form-group input-group col-md-12 mt-md-3">
              <span class="has-float-label">
                <textarea
                  class="form-control web-form-input"
                  style="min-height: 135px; resize: none"
                  id="message"
                  placeholder="Message"
                  v-model="comments"
                ></textarea>

                <label for="chsclsid"> Comments </label>
              </span>
            </div>
          </div>
          <div
            class="pt-3 text-right dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              @click.prevent="updateMoreInfoComments"
              type="button"
              class="btn btnsml"
              id="svbtn"
            >
              <span>Confirm</span>
            </button>
            <button
              @click.prevent="closeMoreInfo"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="inviatePopUp" no-close-on-backdrop no-close-on-esc>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Schedule Interview</h2>
          <br />
          <div class="row">
            <div class="col-lg-12">
              <div class="widjet">
                <div class="widjethdr dispflex">
                  <div style="width: auto">
                    <span class="iconsect blubg" style="padding-left: 11px"
                      ><i class="fas fa-calendar-plus"></i
                    ></span>
                    <h1>Send an Invite</h1>
                  </div>
                </div>
                <div class="p-3 widjetcontent" v-if="inviteDetails.schedule">
                  <div style="padding: 10px; width: 100%; background: #f1f1f1">
                    <label class="custradio" style="margin-bottom: 0"
                      >Online
                      <input
                        type="radio"
                        name="schedule"
                        v-model="offline"
                        value="Online"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="custradio" style="margin-bottom: 0"
                      >Offline
                      <input
                        type="radio"
                        name="schedule"
                        v-model="offline"
                        value="Offline"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div
                    class="form-row"
                    v-if="offline == 'Online'"
                    style="width: 100%"
                  >
                    <div class="form-group input-group col-md-8 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="url"
                          v-model="inviteDetails.schedule.link"
                          name="totseatsEdit"
                          class="form-control form-input"
                          id="totseatsid"
                        />

                        <label for="tchrnameid"
                          >Meeting Link <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="date"
                          v-model="inviteDetails.schedule.meetingDate"
                          onkeydown="return false"
                          name="teacherName"
                          class="form-control form-input"
                          id="tchrnameid"
                        />

                        <label for="tchrnameid"
                          >Date <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="time"
                          v-model="inviteDetails.schedule.meeatingTime"
                          name="teacherName"
                          class="form-control form-input"
                          id="tchrnameid"
                        />

                        <label for="tchrnameid"
                          >Time(HH:MM) <span class="required">*</span></label
                        >
                      </span>
                    </div>
                  </div>
                  <!-- <div class="row" v-if="offline == 'Online'">
								<div class="col-md-2 p5">
                  Meeting Link <span class="required">*</span>
								
								</div>
								<div class="col-md-6 p5">
									<div style="padding:10px;width:100%;background:#f1f1f1" class=""> 
                    <input type="url" v-model="studentObj.schedule.link" name="totseatsEdit"  class="form-control form-input" id="totseatsid"  >
									</div>
								</div>
								
							</div> -->

                  <div class="row" v-else>
                    <div
                      class="form-group input-group mt-md-3"
                      style="padding-right: 1%; padding-left: 1%"
                    >
                      <span class="web-has-float-label">
                        <textarea
                          class="form-control web-form-input"
                          style="
                            min-height: 135px;
                            resize: none;
                            background-color: #eeedeb;
                          "
                          id="message"
                          placeholder="Message"
                          v-model="inviteDetails.schedule.message"
                        ></textarea>

                        <label for="message">Message </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click="sendInviate"
            >
              <span>Send</span>
            </button>
            <button
              @click.prevent="closeInvitePopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Back</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="inviateUpdatePopUp" no-close-on-backdrop no-close-on-esc>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Schedule Interview</h2>
          <br />
          <div class="row">
            <div class="col-lg-12">
              <div class="widjet">
                <div class="widjethdr dispflex">
                  <div style="width: auto">
                    <span class="iconsect blubg" style="padding-left: 11px"
                      ><i class="fas fa-calendar-plus"></i
                    ></span>
                    <h1>Send an Invite</h1>
                  </div>
                </div>
                <div class="p-3 widjetcontent" v-if="inviteDetails.schedule">
                  <div style="padding: 10px; width: 100%; background: #f1f1f1">
                    <label class="custradio" style="margin-bottom: 0"
                      >Online
                      <input
                        type="radio"
                        name="schedule"
                        v-model="offline"
                        value="Online"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="custradio" style="margin-bottom: 0"
                      >Offline
                      <input
                        type="radio"
                        name="schedule"
                        v-model="offline"
                        value="Offline"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div
                    class="form-row"
                    v-if="offline == 'Online'"
                    style="width: 100%"
                  >
                    <div class="form-group input-group col-md-8 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="url"
                          v-model="inviteDetails.schedule.link"
                          name="totseatsEdit"
                          class="form-control form-input"
                          id="totseatsid"
                        />

                        <label for="tchrnameid"
                          >Meeting Link <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="date"
                          v-model="inviteDetails.schedule.meetingDate"
                          onkeydown="return false"
                          name="teacherName"
                          class="form-control form-input"
                          id="tchrnameid"
                        />

                        <label for="tchrnameid"
                          >Date <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="time"
                          v-model="inviteDetails.schedule.meeatingTime"
                          name="teacherName"
                          class="form-control form-input"
                          id="tchrnameid"
                        />

                        <label for="tchrnameid"
                          >Time(HH:MM) <span class="required">*</span></label
                        >
                      </span>
                    </div>
                  </div>
                  <!-- <div class="row" v-if="offline == 'Online'">
								<div class="col-md-2 p5">
                  Meeting Link <span class="required">*</span>
								
								</div>
								<div class="col-md-6 p5">
									<div style="padding:10px;width:100%;background:#f1f1f1" class=""> 
                    <input type="url" v-model="studentObj.schedule.link" name="totseatsEdit"  class="form-control form-input" id="totseatsid"  >
									</div>
								</div>
								
							</div> -->

                  <div class="row" v-else>
                    <div
                      class="form-group input-group mt-md-3"
                      style="padding-right: 1%; padding-left: 1%"
                    >
                      <span class="web-has-float-label">
                        <textarea
                          class="form-control web-form-input"
                          style="
                            min-height: 135px;
                            resize: none;
                            background-color: #eeedeb;
                          "
                          id="message"
                          placeholder="Message"
                          v-model="inviteDetails.schedule.message"
                        ></textarea>

                        <label for="message">Message </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click="reSendInviate"
            >
              <span>Update</span>
            </button>
            <button
              @click.prevent="closeInviteUpdatePopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Back</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal
        id="newstudpop"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >

      <button type="button" class="close"  @click.prevent="closeAdmissionPoup">×</button>
      <h2>Online Admission</h2>
      <button  v-if="isShowEditIcon && !isEdit" class="btn round orange tooltipt" @click="enableEdit" style="position: absolute;right: 69px;top: 20px;" ><i class="fas fa-edit"></i></button>
      <!-- <button type="button" style="position: absolute;right: 69px;top: 20px;" @click.prevent="closeAdmissionPoup">×</button> -->

          <br />
          
      <div class="p-3 widjetcontent">
       

              <div class="subOnetabs" style="height: 2%; background: none">
                <input
                  type="radio"
                  name="subOnetabs"
                  @click="setPopUpActiveTab(1)"
                  id="stdSect"
                  :checked="currentPopUpTab == 1 ? 'checked' : ''"
                />
                <label
                  for="stdSect"
                  style="
                    border: 1px solid rgb(192, 196, 233);
                    border-radius: 15px 15px 0px 0px;
                  "
                  >Student Information <span class="required">*</span></label
                >
                <div class="tab">
                  <div style="height: 350px; overflow-y: auto">
                  <form
                    data-vv-scope="studentvalidateOne"
                    id="stuprofile"
                    accept-charset="utf-8"
                  >
                    <div class="form-row">
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                          
                            type="text"
                            :disabled="!isEdit"
                            v-validate="'required'"
                            name="studentFirstName"
                            v-model="contactDetails.firstName"
                            class="form-control form-input"
                            id="stdFirstName"
                          />
                          <label
                            v-if="errors.first('studentvalidateOne.studentFirstName')"
                            class="vi-error"
                            style="top: -1em"
                            >First name is required</label
                          >
                          <label for="stdFirstName" v-else
                            >First Name <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            v-validate="'required'"
                            type="text"
                            :disabled="!isEdit"
                            name="studentLastName"
                            v-model="contactDetails.lastName"
                            class="form-control form-input"
                            id="stdLastName"
                          />
                          <label
                            v-if="errors.first('studentvalidateOne.studentLastName')"
                            class="vi-error"
                            style="top: -1em"
                            >Last name is required</label
                          >
                          <label for="stdLastName" v-else
                            >Last Name<span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            :disabled="!isEdit"
                            name="stdEmail"
                            v-model="contactDetails.email"
                            class="form-control form-input"
                            id="stdEmail"
                          />
                          <label for="stdEmail">Secondary Email</label>
                        </span>
                      </div>
                    
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                          
                          :max="minDob"

                            v-validate="'required'"
                            type="date"
                            :disabled="!isEdit"
                            onkeydown="return false"
                            name="DOB"
                            v-model="contactDetails.dob"
                            class="form-control form-input"
                            id="stdDob"
                          />
                          <label
                            v-if="errors.first('studentvalidateOne.DOB')"
                            class="vi-error"
                            style="top: -1em"
                            >Date of birth is required</label
                          >

                          <label for="stdDob" v-else
                            >Date of Birth <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="Blood Group"
                            :disabled="!isEdit"
                            class="form-control form-input"
                            id="bloodGroup"
                            v-model="contactDetails.bloodGroup"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(blood, index) in bloodGroupList"
                              :key="index"
                              :value="blood"
                            >
                              {{ blood }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidateOne.Blood Group')"
                            class="vi-error"
                            style="top: -1em"
                            >Blood group is required</label
                          >
                          <label for="bloodGroup" v-else
                            >Blood Group <span class="required">*</span></label
                          >
                        </span>
                      </div>
                    

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="gender"
                            :disabled="!isEdit"
                            class="form-control form-input"
                            id="gender"
                            v-model="contactDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidateOne.gender')"
                            class="vi-error"
                            style="top: -1em"
                            >Gender is required</label
                          >
                          <label for="firstName" v-else
                            >Gender <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                        
                          <select
                          v-validate="'required'"
                            class="form-control form-input"
                            name="applyClass"
                            :disabled="!isEdit"
                            v-model="contactDetails.class"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in classList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.className }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidateOne.applyClass')"
                            class="vi-error"
                            style="top: -1em"
                            >Class is required</label
                          >
                          <label for="stdFirstName" v-else
                            >Class <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            :disabled="!isEdit"
                            name="studentMobileNumber"
                            v-model="contactDetails.studentPhoneNumber"
                            class="form-control form-input"
                            id="stdFirstName"
                          />
                          
                          <label for="stdFirstName" 
                            >Student Mobile Number </label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                          :disabled="!isEdit"
                            type="text"
                            name="studentLastName"
                            v-model="contactDetails.placeofBirth"
                            class="form-control form-input"
                            id="stdLastName"
                          />
                        
                          <label for="stdLastName" 
                            >Place Of Birth </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stdEmail"
                            :disabled="!isEdit"
                            v-model="contactDetails.otherLanguage"
                            class="form-control form-input"
                            id="stdEmail"
                          />
                          <label for="stdEmail">Other Spoken Language's</label>
                        </span>
                      </div>
                    
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stdEmail"
                            :disabled="!isEdit"
                            v-model="contactDetails.aadharNo"
                            class="form-control form-input"
                            id="stdEmail"
                          />
                          <label for="stdDob" 
                            >Aadhar Card Number </label
                          >
                        </span>
                      </div>

                      
                      
                    </div>
                  
                  </form>
                
          </div>
          <div>
               
               <div class="text-center dk_iconsml">
                 <button
                   type="button"
                   class="btn btnsml"
                   id="svbtn"
                   :disabled="!isEdit"
                   v-if="!contactDetails._id"
                  
                   @click.prevent="submitApplication"
                 >
                   <span>Next</span>
                 </button>
                 <button
                   type="button"
                   class="btn btnsml"
                   id="svbtn"
                   :disabled="!isEdit"
                   v-if="contactDetails._id"
                  
                   @click.prevent="updateApplication"
                 >
                   <span>Next</span>
                 </button>
                 <!-- <button
                   type="button"
                   @click.prevent="closeAdmissionPoup"
                   class="btn btncl clsmdl"
                   id="clbtn"
                 >
                   <span>Cancel</span>
                 </button> -->
               </div>
             </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  :style="!contactDetails._id ? 'cursor: not-allowed' : ''"
                  :disabled="!contactDetails._id"
                  :checked="currentPopUpTab == 2 ? 'checked' : ''"
                  @click="setPopUpActiveTab(2)"
                  id="partSec"
                />
                <label
                  for="partSec"
                  :style="!contactDetails._id ? 'cursor: not-allowed;background-color: #f1f3f5;border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;' : 'border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;'"

                
                  >Parent Details <span class="required">*</span></label
                >
                <div class="tab">
                  <div style="height: 350px; overflow-y: auto">
            <form
              data-vv-scope="parentvalidate"
              id="stuprofile"
              accept-charset="utf-8"
            >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3" v-if="!contactDetails.parentId">
                  Already have an account ?
                  

                  <div class="nottble" style="margin-top: 0px">
                    <div class="chkbox" data-title="Select">
                      <label class="custcheckbox">
                        <input
                          :disabled="contactDetails.parentId"
                          type="checkbox"
                          @change="showAccessPopUp"
                          v-model="isAlreadyLogin"
                        />
                        <span class="checkmarkchk"></span>
                      </label>
                    </div>
                  </div>
                </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            :disabled="!isEdit"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="contactDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="
                              errors.first('parentvalidate.pointOfContact')
                            "
                            class="vi-error"
                            style="top: -1em"
                            >Point of contact is required</label
                          >
                          <label for="firstName" v-else
                            >Point of contact
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Father'"
                        >
                          <input
                          :disabled="!isEdit"
                            v-validate="
                              contactDetails.pointOfContact == 'Father'
                                ? 'required'
                                : false
                            "
                            type="text"
                            name="fthrname"
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('parentvalidate.fthrname')"
                            class="vi-error"
                            style="top: -1em"
                            >Father First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father First Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Mother'"
                        >
                          <input
                            type="text"
                            :disabled="!isEdit"
                            name="Mother"
                            v-validate="
                              contactDetails.pointOfContact == 'Mother'
                                ? 'required'
                                : false
                            "
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('parentvalidate.Mother')"
                            class="vi-error"
                            style="top: -1em"
                            >Mother First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother First Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Guardian'"
                        >
                          <input
                            type="text"
                            :disabled="!isEdit"
                            name="Guardian"
                            v-validate="
                              contactDetails.pointOfContact == 'Guardian'
                                ? 'required'
                                : false
                            "
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            placeholder="Guardian Name"
                          />
                          <label
                            v-if="errors.first('parentvalidate.Guardian')"
                            class="vi-error"
                            style="top: -1em"
                            >Guardian First Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian First Name
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Father'"
                        >
                          <input
                            v-validate="
                              contactDetails.pointOfContact == 'Father'
                                ? 'required'
                                : false
                            "
                            type="text"
                            name="fthlastName"
                            v-model="userDetails.lastName"
                            :disabled="!isEdit"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('parentvalidate.fthlastName')"
                            class="vi-error"
                            style="top: -1em"
                            >Father Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father Last Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Mother'"
                        >
                          <input
                            type="text"
                            name="motherLastNameval"
                            v-validate="
                              contactDetails.pointOfContact == 'Mother'
                                ? 'required'
                                : false
                            "
                            v-model="userDetails.lastName"
                            :disabled="!isEdit"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('parentvalidate.motherLastNameval')"
                            class="vi-error"
                            style="top: -1em"
                            >Mother Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother Last Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Guardian'"
                        >
                          <input
                            type="text"
                            name="Guardianaaasa"
                            v-validate="
                              contactDetails.pointOfContact == 'Guardian'
                                ? 'required'
                                : false
                            "
                            v-model="userDetails.lastName"
                            :disabled="!isEdit"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('parentvalidate.Guardianaaasa')"
                            class="vi-error"
                            style="top: -1em"
                            >Guardian Last Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian Last Name
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            type="email"
                            v-validate="'required'"
                            name="ptemail"
                            :disabled="!isEdit"
                            v-model="userDetails.email"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('parentvalidate.ptemail')"
                            class="vi-error"
                            style="top: -1em"
                            >Primary Email is required</label
                          >
                          <label for="ptemailid" v-else
                            >Primary Email
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            maxlength="10"
                            v-validate="'required'"
                            name="ptph"
                            v-model="userDetails.phoneNo"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('parentvalidate.ptph')"
                            class="vi-error"
                            style="top: -1em"
                            >Phone is required</label
                          >

                          <label v-else for="ptphid"
                            >Phone <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            type="date"
                            name="nationality"
                            :disabled="!isEdit"
                            v-model="userDetails.dob"
                            class="form-control form-input"
                            id="natid"
                          />
                          <label for="natid">Date Of Birth </label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                            :disabled="!isEdit"
                            v-model="userDetails.education"
                            class="form-control form-input"
                            id="natid"
                          />
                          <label for="natid">Educational qualification </label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                            :disabled="!isEdit"
                            v-model="userDetails.aadharCardNo"
                            class="form-control form-input"
                            id="natid"
                          />
                          <label for="natid">Aadhar Card Number </label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            type="text"
                            :disabled="!isEdit"
                            name="occ"
                            v-model="userDetails.occupation"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid">Occupation </label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            :disabled="!isEdit"
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                          />
                          <label for="relid">Religion </label>
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                            :disabled="!isEdit"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                          />
                          <label for="natid">Nationality </label>
                        </span>
                      </div>

                     

                      <div class="form-group input-group col-md-6 mt-3" v-if="!isAlreadyLogin">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stadr"
                            :disabled="!isEdit"
                            v-model="userDetails.address"
                            class="form-control form-input"
                            id="stadrid"
                          />
                          <label for="stadrid">Address Details</label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3" v-if="isAlreadyLogin">
                       UserDetail Verified
                      </div>
                    </div>
             
            </form>
          </div>
          <div >
               
               <div class="text-center dk_iconsml" v-if="!isAlreadyLogin">
                 <div v-if="isEdit">
                 <button v-if="contactDetails.parentId"
                   type="button"
                   class="btn btnsml"
                   id="svbtn"
                   @click="updateParentDetails"
                  
                 >
                   <span>Next</span>
                 </button>
                 <button v-else
                   type="button"
                   class="btn btnsml"
                   id="svbtn"
                   @click="saveParentDetails"
                  
                 >
                   <span>Next</span>
                 </button>
                 </div>
                 
                
               </div>
             </div>
                </div>
                <input
                  type="radio"
                  :disabled="!contactDetails._id"
                  :style="!contactDetails._id ? 'cursor: not-allowed' : ''"
                  :checked="currentPopUpTab == 3 ? 'checked' : ''"
                  @click="setPopUpActiveTab(3)"
                  name="tabs"
                  id="contsec"
                />
                <label
                  for="contsec"
                  :style="!contactDetails._id ? 'cursor: not-allowed;background-color: #f1f3f5;border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;' : 'border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;'"

                  >Contact Details
                </label>
                <div class="tab">
                  <div style="height: 350px; overflow-y: auto">
            <form
              data-vv-scope="studentvalidate"
              id="stuprofile"
              accept-charset="utf-8"
            >
             
              <div class="form-row">
                     <span style="width: 100%;" class="pl-1 my-2">
                                  <strong>Current Address</strong> &nbsp;
                                  &nbsp;

                                 
                                </span>
                    <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.addressOne"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid"
                            >Address Line 1 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.addressTwo"
                            class="form-control form-input"
                            id="occid"
                            :disabled="!isEdit"
                          />
                          <label for="occid"
                            >Address Line 2 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.country"
                            class="form-control form-input"
                            id="occid"
                            :disabled="!isEdit"
                          />
                          <label for="occid"
                            >Country</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gendddfddder"
                            class="form-control form-input"
                            id="gedfdnder"
                            :disabled="!isEdit"
                            v-model="contactDetails.state"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in stateList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>

                         
                          <label for="occid"
                            >State </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.district"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid"
                            >City/Town</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.postalCode"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid"
                            >Pin Code</label
                          >
                        </span>
                      </div>
                      <!-- <span style="width: 100%;" class="pl-1 my-2">
                                 

                                  <span style="color: #6c757d" class="ml-3"
                                    >Permanent address same as current address ?
                                    </span
                                  >
                                  <input
                                    @change="setPermentAddress(contactDetails.isPermentAddress)" v-model="contactDetails.isPermentAddress"
                                    type="checkbox"
                                  />
                                </span>
                      -->
                    
                  </div>
                  <div class="form-row" >
                  <div
                            class="form-group input-group col-md-6 mt-3"
                            
                          >
                             
                             
                          <span >  Permanent address same as current address ?</span>

                               <div class="nottble" style="margin-top:0px;">
                                <div class=" chkbox" data-title="Select">
                                    <label class="custcheckbox">
                                      <input  type="checkbox" :disabled="!isEdit" @change="setPermentAddress(contactDetails.isPermentAddress)" v-model="contactDetails.isPermentAddress" />
                                      <span class="checkmarkchk"></span>
                                    </label>
                                  </div>
                                  
                                </div>
                               
                                 
                            
                          </div>
                           </div>
                  <div class="form-row" v-if="!contactDetails.isPermentAddress">
                     <span style="width: 100%;" class="pl-1 my-2">
                                  <strong>Permanent Address</strong> &nbsp;
                                  &nbsp;

                                 
                                </span>
                    <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentaddressOne"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid"
                            >Address Line 1 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentaddressTwo"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid"
                            >Address Line 2 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentcountry"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid"
                            >Country</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gendddfddder"
                            class="form-control form-input"
                            id="gedfdnder"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentstate"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in stateList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>

                        
                          <label for="occid"
                            >State </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentdistrict"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid"
                            >City/Town</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentpostalCode"
                            class="form-control form-input"
                            id="occid"
                          />
                          <label for="occid"
                            >Pin Code</label
                          >
                        </span>
                      </div>
                    
                     
                    
                  </div>
              
            </form>
          </div>
          <div>
               
               <div class="text-center dk_iconsml">
                 <button v-if="isEdit"
                   type="button"
                   class="btn btnsml"
                   id="svbtn"
                   @click.prevent="saveContactAddress"
                  
                 >
                   <span>Next</span>
                 </button>
                 
               </div>
             </div>
                </div>
                <input
                  type="radio"
                  :checked="currentPopUpTab == 4 ? 'checked' : ''"
                  :style="!contactDetails._id ? 'cursor: not-allowed' : ''"
                  :disabled="!contactDetails._id"
                  @click="setPopUpActiveTab(4)"
                  name="tabs"
                  id="acdsec"
                />
                <label
                  for="acdsec"
                  :style="!contactDetails._id ? 'cursor: not-allowed;background-color: #f1f3f5;border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;' : 'border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;'"

                  >Academic and background</label
                >
                <div class="tab">
                  <div style="height: 350px; overflow-y: auto">
            <form
              data-vv-scope="studentvalidate"
              id="stuprofile"
              accept-charset="utf-8"
            >
            <div class="form-row">
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="stdEmail"
                      :disabled="!isEdit"
                      v-model="contactDetails.previousSchool"
                      class="form-control form-input"
                      id="stdEmail"
                    />
                    <label for="stdEmail">Last or Current School Name</label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="stdEmail"
                      :disabled="!isEdit"
                      v-model="contactDetails.lastSchoolGrade"
                      class="form-control form-input"
                      id="stdEmail"
                    />
                    <label for="stdEmail">Last or Current Grade</label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="stdEmail"
                      :disabled="!isEdit"
                      v-model="contactDetails.lastSchoolCity"
                      class="form-control form-input"
                      id="stdEmail"
                    />
                    <label for="stdEmail">School City</label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="stdEmail"
                      :disabled="!isEdit"
                      v-model="contactDetails.lastSchoolCountry"
                      class="form-control form-input"
                      id="stdEmail"
                    />
                    <label for="stdEmail">School Country</label>
                  </span>
                </div>
              </div>
             
             
            </form>
          </div>
          <div >
               
               <div class="text-center dk_iconsml">
                 <button v-if="isEdit"
                   type="button"
                   class="btn btnsml"
                   id="svbtn"
                  @click="saveLastAcademicDetails"
                 >
                   <span>Next</span>
                 </button>
                
               </div>
             </div>
                </div>
                <input
                  type="radio"
                  :checked="currentPopUpTab == 5 ? 'checked' : ''"
                  :style="!contactDetails._id ? 'cursor: not-allowed' : ''"
                  @click="setPopUpActiveTab(5)"
                  :disabled="!contactDetails._id"
                  name="tabs"
                  id="upldSec"
                />
                <!--                   :disabled="!contactDetails._id"
 :style="!contactDetails._id ? 'cursor: not-allowed;background-color: #f1f3f5;border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;' : 'border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;'" -->
                <label
                  for="upldSec"
                  :style="!contactDetails._id ? 'cursor: not-allowed;background-color: #f1f3f5;border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;' : 'border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;'"


                  >Documents
                </label>
                <div class="tab">
                  <div v-if="contactDetails.eligibilityCriteriaDocuments && contactDetails.eligibilityCriteriaDocuments.length > 0">
                  <div class="form-group row" v-for="(data, index) in contactDetails.eligibilityCriteriaDocuments" :key="index">
                          <div class="col-1" style="padding-top: 10px;">
                            <div class="nottble" style="margin-top: 0px">
                    <div class="chkbox" data-title="Select">
                      <label class="custcheckbox">
                        <input
                        disabled
                          type="checkbox"
                          
                          :checked="data.path"
                        />
                        <span class="checkmarkchk"></span>
                      </label>
                    </div>
                  </div>
                              <!-- <div class="radios">
                                  <div class="row">
                                      <div class="form-check col-3">
                                          <input class="styled-checkbox" id="NIC" type="checkbox" :checked="data.path" disabled>
                                          <label class="abs-position" for="NIC"></label>
                                      </div>
                                  </div>
                              </div> -->
                          </div>
                          <div class="col-4">
                              <label>
                                  <span>{{ data.name }} </span>
                              </label>
                          </div>
                          <div class="col-6">
                            <button class="btn btn-doc btn-sm" style="background-color: darkseagreen;" @click.prevent="preViewDocument(data.path)" v-if="data.path">
                              View
                            </button>
                            <button class="btn btn-doc btn-sm" style="margin-left: 10px;background-color: indianred;" @click.prevent="removeDocument(index,data.key)"   v-if="data.path">
                              Delete
                            </button>
                           
                            <input 
                            v-else
                              type="file"
                              :ref="data.name"
                              :name="data.name"
                              accept="application/pdf, image/jpg, image/png"
                              @change="updateFile(data.apiName,data.name)"/>
                            
                             
                          </div>
                          
                  </div>
                  <div >
               
               <div class="text-center dk_iconsml">
                 <button v-if="isEdit"
                   type="button"
                   class="btn btnsml"
                   id="svbtn"
                  @click="closeAdmissionPoup"
                 >
                   <span>Submit</span>
                 </button>
                
               </div>
             </div>
                  </div>
                   <div class="form-group row" v-else>
                    <div class="col-12" style="text-align: center;">

                      <span> No Required Documents</span>
                    </div>
                   </div>

                </div>
              </div>
            </div>
        <!-- <div class="contact-form">
          <h2>Online Admission</h2>
          <br />
          <div style="height: 350px; overflow-y: auto">
            <form
              data-vv-scope="studentvalidate"
              id="stuprofile"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      v-validate="'required'"
                      name="studentFirstName"
                      v-model="contactDetails.firstName"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label
                      v-if="errors.first('studentvalidate.studentFirstName')"
                      class="vi-error"
                      style="top: -1em"
                      >First name is required</label
                    >
                    <label for="stdFirstName" v-else
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      v-validate="'required'"
                      type="text"
                      name="studentLastName"
                      v-model="contactDetails.lastName"
                      class="form-control form-input"
                      id="stdLastName"
                    />
                    <label
                      v-if="errors.first('studentvalidate.studentLastName')"
                      class="vi-error"
                      style="top: -1em"
                      >Last name is required</label
                    >
                    <label for="stdLastName" v-else
                      >Last Name<span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="stdEmail"
                      v-model="contactDetails.email"
                      class="form-control form-input"
                      id="stdEmail"
                    />
                    <label for="stdEmail">Secondary Email</label>
                  </span>
                </div>
              
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      v-validate="'required'"
                      type="date"
                      onkeydown="return false"
                      name="DOB"
                      v-model="contactDetails.dob"
                      class="form-control form-input"
                      id="stdDob"
                    />
                    <label
                      v-if="errors.first('studentvalidate.DOB')"
                      class="vi-error"
                      style="top: -1em"
                      >Date of birth is required</label
                    >

                    <label for="stdDob" v-else
                      >Date of Birth <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <select
                      v-validate="'required'"
                      name="Blood Group"
                      class="form-control form-input"
                      id="bloodGroup"
                      v-model="contactDetails.bloodGroup"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(blood, index) in bloodGroupList"
                        :key="index"
                        :value="blood"
                      >
                        {{ blood }}
                      </option>
                    </select>
                    <label
                      v-if="errors.first('studentvalidate.Blood Group')"
                      class="vi-error"
                      style="top: -1em"
                      >Blood group is required</label
                    >
                    <label for="bloodGroup" v-else
                      >Blood Group <span class="required">*</span></label
                    >
                  </span>
                </div>
               

                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <select
                      v-validate="'required'"
                      name="gender"
                      class="form-control form-input"
                      id="gender"
                      v-model="contactDetails.gender"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in genderList"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <label
                      v-if="errors.first('studentvalidate.gender')"
                      class="vi-error"
                      style="top: -1em"
                      >Gender is required</label
                    >
                    <label for="firstName" v-else
                      >Gender <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                  <span class="has-float-label">
                  
                    <select
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="contactDetails.class"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in classList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.className }}
                      </option>
                    </select>

                    <label for="stdFirstName"
                      >Class <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  Already have an account ?
                  <span class="required">*</span>

                  <div class="nottble" style="margin-top: 0px">
                    <div class="chkbox" data-title="Select">
                      <label class="custcheckbox">
                        <input
                          type="checkbox"
                          @change="showAccessPopUp"
                          v-model="isAlreadyLogin"
                        />
                        <span class="checkmarkchk"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <fieldset
                  class="accordion-group-container"
                  v-if="!isAlreadyLogin"
                >
                  <div>
                    <label
                      style="margin: 0"
                      for="accordiongroup-1"
                      class="blutitle accordion-label"
                    >
                      Parent Details
                    </label>
                    <div class="form-row">
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="contactDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="
                              errors.first('studentvalidate.pointOfContact')
                            "
                            class="vi-error"
                            style="top: -1em"
                            >Point of contact is required</label
                          >
                          <label for="firstName" v-else
                            >Point of contact
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Father'"
                        >
                          <input
                            v-validate="
                              contactDetails.pointOfContact == 'Father'
                                ? 'required'
                                : false
                            "
                            type="text"
                            name="fthrname"
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrname')"
                            class="vi-error"
                            style="top: -1em"
                            >Father Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Mother'"
                        >
                          <input
                            type="text"
                            name="Mother"
                            v-validate="
                              contactDetails.pointOfContact == 'Mother'
                                ? 'required'
                                : false
                            "
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            placeholder="Mother Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Mother')"
                            class="vi-error"
                            style="top: -1em"
                            >Mother Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother Name <span class="required">*</span></label
                          >
                        </span>
                        <span
                          class="has-float-label"
                          v-if="contactDetails.pointOfContact == 'Guardian'"
                        >
                          <input
                            type="text"
                            name="Guardian"
                            v-validate="
                              contactDetails.pointOfContact == 'Guardian'
                                ? 'required'
                                : false
                            "
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            placeholder="Guardian Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Guardian')"
                            class="vi-error"
                            style="top: -1em"
                            >Guardian Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian Name
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="userDetails.occupation"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Occupation"
                          />
                          <label for="occid">Occupation </label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid">Religion </label>
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid">Nationality </label>
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="email"
                            v-validate="'required'"
                            name="ptemail"
                            v-model="userDetails.email"
                            class="form-control form-input"
                          />
                          <label
                            v-if="errors.first('studentvalidate.ptemail')"
                            class="vi-error"
                            style="top: -1em"
                            >Primary Email is required</label
                          >
                          <label for="ptemailid" v-else
                            >Primary Email
                            <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            maxlength="10"
                            v-validate="'required'"
                            name="ptph"
                            v-model="userDetails.phoneNo"
                            class="form-control form-input"
                            placeholder="Phone"
                          />
                          <label
                            v-if="errors.first('studentvalidate.ptph')"
                            class="vi-error"
                            style="top: -1em"
                            >Phone is required</label
                          >

                          <label v-else for="ptphid"
                            >Phone <span class="required">*</span></label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stadr"
                            disabled
                            v-model="userDetails.address"
                            class="form-control form-input"
                            id="stadrid"
                            placeholder="Address Details"
                          />
                          <label for="stadrid">Address Details</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div style="border-top: 1px solid #e5e5e5">
               
                <div class="text-center dk_iconsml">
                  <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="submitApplication"
                  >
                    <span>Save</span>
                  </button>
                  <button
                    type="button"
                    @click.prevent="closeAdmissionPoup"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> -->
      </b-modal>
      <b-modal id="deleteFeeTypePopup" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close" @click.prevent="closeAccessPopUp">
          ×
        </button>
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2 v-if="isVerifyCode == false">Email Verification</h2>
          <br />
          <form
            class="w-100"
            data-vv-scope="passWordValidate"
            id="addteacher"
            accept-charset="utf-8"
          >
            <div class="form-row m-0" v-if="isVerifyCode == false">
              <div class="form-group input-group mt-3 col-md-3"></div>
              <div
                class="form-group input-group mt-3 col-md-4"
                style="width: 200px"
              >
                <span class="web-has-float-label">
                  <input
                    v-model="verfiyMailId"
                    class="form-control form-input"
                  />

                  <label for="passwordid"
                    >Email<span class="required">*</span></label
                  >
                </span>
              </div>

              <button
                type="submit"
                :disabled="!verfiyMailId"
                @click.prevent="checkmailValid(verfiyMailId)"
                class="web-custbutton"
                style="
                  width: 102px;
                  margin-left: 39px;
                  height: 41px;
                  padding: -8px;
                  margin-top: 13px;
                "
              >
                Check
              </button>
              <button
                @click.prevent="closeAccessPopUp"
                type="button"
                class="btn btncl"
                id="clbtn"
                style="
                  width: 123px;
                  margin-left: 2px;
                  height: 41px;
                  margin-top: 13px;
                "
              >
                <span>Cancel</span>
              </button>
            </div>
            <div class="contact-form" v-else>
              <h4>Enter 4 digit code sent to your email id</h4>
              <br />
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="web-has-float-label">
                    <input
                      type="number"
                      onKeyPress="if(this.value.length==4) return false;"
                      name="verifyCode"
                      class="form-control web-form-input"
                      id="verifyCodeid"
                      v-model="userVerifyCode"
                      min="4"
                    />

                    <label for="verifyCodeid"
                      >4-digit code <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="pt-3 text-center dk_iconsml">
                  <button
                    :disabled="!userVerifyCode"
                    type="button"
                    @click.prevent="verifyPrimaryMailCode"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    <i class="far fa-save"></i> <span>Verify</span>
                  </button>
                  <button
                    @click.prevent="closeAccessPopUp"
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                    <i class="far fa-times-circle"></i> <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- </div> -->
      </b-modal>
    </div>

    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    />
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import VueMonthlyPicker from "vue-monthly-picker";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "candidates",
  data() {
    return {
      componentKey: 0,
      isEdit: false,
      viLoader: false,
      searchWords: "",
      skip: 0, // set the skip info
      limit: 7, // set the limit info
      onlineAdmissionList: [],
      updateActionId: null,
      minPlanDate: null,
      maxPlanDate: null,
      updateActionType: "",
      sectionList: [],
      studentObj: {},
      currentTab: 1,
      currentPopUpTab: 1,
      currentSubTab: 2.1,
      isVerifyCode: false,
      generatedVerifyCode: null,
      verifyedParentDetails: {},
      inviteDetails: {},
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      isAlreadyLogin: false,
      showisAlreadyLogin: false,
      countAll: true,
      showAssign: false,
      totalCount: 0,
      academicYearList: [],
      eligibilityCriteria: [],
      candidateEditDetails: {},
      selectAcademicYear: null,
      comments: "",
      contactDetails: {
        middleName : "",
        fatherFirstName : "",
        fatherLastName : "",
        fatherOccuption : "",
        motherFirstName : "",
        motherLastName : "",
        motherOccuption : "",
        guardianFirstName : "",
        guardianLastName : "",
        guardianOccuption : "",
        mobileThree: null,
        isPermentAddress: false,
        isrtequota: false,
        isDisability: false,
        permentaddressOne  : "",
        permentaddressTwo : "",
        permentstate : "",
        permentdistrict : "",
        permentpostalCode : "",
        permentcountry : "",
        category : "",
        previousSchool : "",
        lastSchoolGrade: '',
        lastSchoolCity: '',
        lastSchoolCountry: '',
        previousClassPassed : "",
        firstName: "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        dateOfLeaving: null,
        pointOfContact: "Father",
        academicYear: null,
        gender: "Male",
        placeofBirth: '',
        aadharNo: '',
        studentPhoneNumber: null,
        otherLanguage: '',
        accountId: null,
        email: "",
        mobileOne: 0,
        mobileTwo: 0,
        image: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: "Completed",
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        parentId: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: "",
        className: "",
        admissionDeadline: null,
        eligibilityCriteria: [],
      },
      userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
        education: '',
        dob: null,
        aadharCardNo: ''

      },
      verfiyMailId: "",
      verfiyMailStatus: "",
      actionComments: "",
      offline: "Online",
      categoryList: ["General/OC","BC","SC","MBC","ST","Minority"],
      stateList: [
         "Andaman and Nicobar Islands",
        "Andhra Pradesh",
         "Arunachal Pradesh",
         "Assam",
         "Bihar",
         "Chandigarh",
         "Chhattisgarh",
         "Dadra and Nagar Haveli",
         "Daman and Diu",
         "Delhi",
         "Goa",
         "Gujarat",
         "Haryana",
         "Himachal Pradesh",
         "Jammu and Kashmir",
         "Jharkhand",
         "Karnataka",
         "Kerala",
         "Lakshadweep",
         "Madhya Pradesh",
         "Maharashtra",
         "Manipur",
         "Meghalaya",
         "Mizoram",
         "Nagaland",
         "Odisha",
         "Puducherry",
         "Punjab",
         "Rajasthan",
         "Sikkim",
        "Tamil Nadu",
        "Telangana",
         "Tripura",
         "Uttar Pradesh",
         "Uttarakhand",
         "West Bengal",
      ],
      statusList: [
        "Submitted",
        "Active",
        "Enrolled",
        "Need More Info",
        "Documents Missing",
        "Assessment scheduled",
      ],
      pointOfList: ["Father", "Mother", "Guardian"],
      genderList: ["Male", "Female"],
      userVerifyCode: null,
      isShowEditIcon: false,
      minDob: null,
      newApplicationList: [],
      acceptedApplicationList: [],
      rejectedApplicationList: [],
      scheduledApplicationList: [],
      waitingListApplicationList: [],
      rejectInInterviewApplicationList: [],
      admissionApprovedApplicationList: [],
      accountClearedApplicationList: [],
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"],
      classList: [],
      EligibilityCriteriaList: [],
      EligibilityCriteriaListClone: [
        {
          label: "Address Proof",
          value: "Address Proof",
        },
        {
          label: "Identify Proof",
          value: "Identify Proof",
        },
        {
          label: "TC Certificate",
          value: "TC Certificate",
        },
        {
          label: "Residence Proof",
          value: "Residence Proof",
        },
        {
          label: "Birth Certificate",
          value: "Birth Certificate",
        },
        {
          label: "Transfer Certificate",
          value: "Transfer Certificate",
        },
        {
          label: "Parent Aadhaar Card",
          value: "Parent Aadhaar Card",
        },
        {
          label: "Child Aadhar Card",
          value: "Child Aadhar Card",
        },
        {
          label: "Medical Reports",
          value: "Medical Reports",
        },
        {
          label: "Photograph  Child",
          value: "Photograph  Child",
        },
        {
          label: "Photograph  Parents/Guardian",
          value: "Photograph  Parents/Guardian",
        },
        {
          label: "Marksheet/Report card (if applicable)",
          value: "Marksheet/Report card (if applicable)",
        },
        {
          label: "Migration Certificate if any",
          value: "Migration Certificate if any",
        },
        {
          label: "Goverment Proof",
          value: "Goverment Proof",
        },
      ],
    };
  },
  created() {
    this.getAcademicYearList();
    // this.getOnlineAdmissionList("new");
    this.setDob()

    this.getClassList();
    this.getNewOnlineAdmissionList();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },
  watch: {
    searchWords: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.skip = 0;
        (this.currentPageNumber = 1),
          (this.lastPageNumber = 1),
          (this.selected = 1),
          (this.totalCount = 0),
          (this.countAll = true),
          this.getOnlineAdmissionList("old");
      }
    },
  },
  methods: {
    setDob(){

        let start = new Date()

        let startmonth = start.getMonth() + 1
        let startdate = start.getDate()
        let startYear = start.getFullYear()

        if (startdate < 10) {
          startdate = '0' + startdate;
        }
        if (startmonth < 10) {
          startmonth = '0' + startmonth;
        }
        this.minDob = startYear + "-" + startmonth + "-" + startdate;

        },
    showAccessPopUp() {
      //this.isAlreadyLogin = false
      if (this.isAlreadyLogin) {
        this.$bvModal.show("deleteFeeTypePopup");
      } else {
        (this.verfiyMailId = ""), (this.verfiyMailStatus = "");
      }
    },
    closeAccessPopUp() {
      (this.verfiyMailId = ""),
        (this.verfiyMailStatus = ""),
        (this.isVerifyCode = false);
      this.generatedVerifyCode = null;
      this.verifyedParentDetails = {};
      this.isAlreadyLogin = false;
      this.showisAlreadyLogin = false
      this.$bvModal.hide("deleteFeeTypePopup");
      //this.accessPwd = ''
    },
    enableEdit(){
      this.isEdit = true

    },
   closeAdmissionPoup() {
    this.showisAlreadyLogin = false

      this.currentTab = 1;

      this.currentPopUpTab = 1,
       this.isEdit = false
       this.isShowEditIcon = false

       this.$bvModal.hide("newstudpop");
    
      //this.currentTab = 1
      this.setActiveTab(1)
     // this.currentSubTab = 1.1
      this.contactDetails = {
        firstName: "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        dateOfLeaving: null,
        pointOfContact: "Father",
        academicYear: null,
        gender: "Male",
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: 0,
        mobileTwo: 0,
        image: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: "Completed",
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        parentId: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: "",
        className: "",
        admissionDeadline: null,
        eligibilityCriteria: [],
      },
      this.userDetails = {
          firstName: "",
          lastName: "",
          fatherName: "",
          motherName: "",
          email: "",
          phoneNo: null,
          password: "",
          role: "",
          membership: "Free",
          userId: "",
          gender: "",
          occupation: "",
          religion: "",
          nationality: "",
          address: "",
          registeredAs: "",
          userDevices: [],
          profile: null,
        };
    this.getNewOnlineAdmissionList();
    this.componentKey += 1;

    },
    setPopUpActiveTab(index) {
      this.currentPopUpTab = index;
      // if(index == 2){
      //   this.currentSubTab = 2.1
      // }
      // if(index == 3){
      //   this.currentSubTab = 3.1
      // }
      // if(index == 5){
      //   this.currentSubTab = 5.1
      // }

    },
    setActiveSubTab(index) {
      this.currentSubTab = index;
      // if(index == 2){
      //   this.currentSubTab = 2.1
      // }

    },
    // :checked="currentSubTab == 2.2 ? 'checked' : ''"
    //                   @click="setActiveSubTab(2.2)"
    async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.classList = [];
        const response = await ViService.viXGet(
          "/contact/getClassList",
          userData.token
        );
        if (response.isSuccess) {
          this.classList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    setActiveTab(index) {
      this.currentTab = index;
      if(index == 2){
        this.currentSubTab = 2.1
      }
      if(index == 3){
        this.currentSubTab = 3.1
      }
      if(index == 5){
        this.currentSubTab = 5.1
      }

    },
    previousRoute() {
      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.account.version == "freeVersion") {
        localStorage.setItem("activeTab", "/enrollment/school");
        localStorage.setItem("previousTab", "/enrollment/school");
        this.$router.push({ path: "/enrollment/school" });
      } else {
        // let data = localStorage.getItem("previousTab");
        // localStorage.setItem("activeTab", data);
        // localStorage.setItem("previousTab", this.$route.path);
        // this.$router.push({ path: data });
        localStorage.setItem("activeTab", "/admission/online/home");
        localStorage.setItem("previousTab", "/admission/online/home");
        this.$router.push({ path: "/admission/online/home" });
      }
    },
    async navToSelectedPage(selectedPageNo) {
      this.skip = this.limit * (selectedPageNo - 1);

      this.countAll = false;

      this.getOnlineAdmissionList("old");
    },

    async goToNextPage() {
      this.countAll = false;
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = this.limit * (this.currentPageNumber - 1);

      this.getOnlineAdmissionList("old");
    },

    async goToPreviousPage() {
      this.countAll = false;
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = this.limit * (this.currentPageNumber - 1);

      this.getOnlineAdmissionList("old");
    },
    addMoreDocumentsPopUp() {
      const getNameDocs = this.studentObj.eligibilityCriteriaDocuments.map(
        (x) => x.name
      );

      const filterCritrDoc = this.EligibilityCriteriaListClone.filter(
        (x) => getNameDocs.indexOf(x.value) == -1
      );

      this.EligibilityCriteriaList = filterCritrDoc;

      this.$bvModal.show("addDocuments");
    },

    async updateMoreInfoComments() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let obj = {
          comments: this.comments,
          admissionStatus: "Need More Info",
        };

        const response = await ViService.viXPut(
          `/admission/updateOnlineAdmission/${this.studentObj._id}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.closeMoreInfo();
          this.getOnlineAdmissionList("old");
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async removeDocument(index,key){
        let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
         let newList = []

         this.contactDetails.eligibilityCriteriaDocuments.forEach((x, i) => {
         if(i == index){
           x.key = '',
           x.path = ''
         }
         newList.push(x)
				});

        const response = await ViService.viXPut(
          `/document/removeOnlineAdmissionDocument/${this.contactDetails._id}/${key}`,
          newList,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.contactDetails.eligibilityCriteriaDocuments = newList
          this.getNewOnlineAdmissionList();

        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      
      
     
    },
    // async removeDocument(index) {
    //   let removeDuplicateList = [
    //     ...this.contactDetails.eligibilityCriteriaDocuments,
    //   ];

    //   removeDuplicateList.splice(index, 1);

    //   let userData = secureUI.sessionGet("user");

    //   if (!userData) {
    //     this.$toasted.error("Please login and do the action");
    //   } else {
    //     let newList = []

    //     this.contactDetails.eligibilityCriteriaDocuments.forEach((x, i) => {
    //     if(i == index){
    //       x.key = '',
    //       x.path = ''
    //     }
    //     newList.push(x)
    //     });

    //     let obj = {
    //       eligibilityCriteriaDocuments: removeDuplicateList,
    //     };

    //     const response = await ViService.viXPut(
    //       `/admission/updateOnlineAdmission/${this.contactDetails._id}`,
    //       obj,
    //       userData.token
    //     );

    //     if (response.isSuccess) {
    //       this.contactDetails.eligibilityCriteriaDocuments.splice(index, 1);
    //       this.$toasted.success("Remove Successfully");
    //     } else {
    //       this.$toasted.error(response.message);
    //       if (response.message == "Your session has expired, please login") {
    //         localStorage.removeItem("user");
    //         this.$router.push("/login");
    //       }
    //     }
    //   }
    // },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    preViewDocument(path) {
      window.open(path, "_blank");
    },
    updateAction(onlineAdmissionId, actionType) {
      (this.updateActionId = onlineAdmissionId),
        (this.updateActionType = actionType);
      this.$bvModal.show("actionPopup");
    },
    updateCancelAction(onlineAdmissionId, actionType, data) {
      (this.updateActionId = onlineAdmissionId),
        (this.actionComments = data.actionComments || "");
      this.updateActionType = actionType;
      this.$bvModal.show("actionCancelPopup");
    },

    openEditInfoPopUp(index) {
      const details = this.onlineAdmissionList[index];

      this.candidateEditDetails = details;

      this.studentObj = {};
      const studentDetails = this.onlineAdmissionList[index];
      const cloneStudentDetails = { ...studentDetails };
      this.studentObj = cloneStudentDetails;
      this.studentObj.comments = this.studentObj.comments || "";
      this.comments = this.studentObj.comments;

      if (this.studentObj.dob) {
        this.studentObj.dob = this.studentObj.dob.substring(0, 10);
      }
      if (this.studentObj.doj) {
        this.studentObj.doj = this.studentObj.doj.substring(0, 10);
      }
      if (this.studentObj.dateOfLeaving) {
        this.studentObj.dateOfLeaving = this.studentObj.dateOfLeaving.substring(
          0,
          10
        );
      }

      if (this.studentObj.admissionStatus == "Approved") {
        this.statusList.push("Approved");
      }

      if (this.studentObj.admissionStatus == "Rejected") {
        this.statusList.push("Rejected");
      }

      if (this.studentObj.admissionStatus == "Canceled") {
        this.statusList.push("Canceled");
      }

      if (this.studentObj.admissionStatus == "Confirm") {
        this.statusList.push("Confirm");
      }

      this.$bvModal.show("needMoreInfoPopUp");
    },
    openinviatePopUp(index) {
      const details = this.acceptedApplicationList[index];

      const studentDetails = this.acceptedApplicationList[index];
      const cloneStudentDetails = { ...studentDetails };

      this.inviteDetails = cloneStudentDetails;
      
      this.inviteDetails.schedule = this.inviteDetails.schedule || {
        type: "Online",
        link: "",
        message: "",
        meetingDate: null,
        meeatingTime: "",
      };

      this.offline = this.inviteDetails.schedule.type;

      this.$bvModal.show("inviatePopUp");
    },
    interviewResudle(index,type) {
      if(type == 'scheduledApplicationList'){
        const details = this.scheduledApplicationList[index];

        const studentDetails = this.scheduledApplicationList[index];
        const cloneStudentDetails = { ...studentDetails };

        this.inviteDetails = cloneStudentDetails;
        this.inviteDetails.schedule = this.inviteDetails.schedule || {
          type: "Online",
          link: "",
          message: "",
          meetingDate: null,
          meeatingTime: "",
        };

        this.offline = this.inviteDetails.schedule.type;

        this.$bvModal.show("inviateUpdatePopUp");
      }
      if(type == 'rejectInInterviewApplicationList'){
        const details = this.rejectInInterviewApplicationList[index];

        const studentDetails = this.rejectInInterviewApplicationList[index];
        const cloneStudentDetails = { ...studentDetails };

        this.inviteDetails = cloneStudentDetails;
        this.inviteDetails.schedule = this.inviteDetails.schedule || {
          type: "Online",
          link: "",
          message: "",
          meetingDate: null,
          meeatingTime: "",
        };

        this.offline = this.inviteDetails.schedule.type;

        this.$bvModal.show("inviateUpdatePopUp");
      }
      if(type == 'waitingListApplicationList'){
         const details = this.waitingListApplicationList[index];

          const studentDetails = this.waitingListApplicationList[index];
          const cloneStudentDetails = { ...studentDetails };

          this.inviteDetails = cloneStudentDetails;
          this.inviteDetails.schedule = this.inviteDetails.schedule || {
            type: "Online",
            link: "",
            message: "",
            meetingDate: null,
            meeatingTime: "",
          };

          this.offline = this.inviteDetails.schedule.type;

          this.$bvModal.show("inviateUpdatePopUp");
      }
    
    },
    // interviewResudle(){

    // }
    closeInvitePopUp() {
      this.inviteDetails = {};
      this.$bvModal.hide("inviatePopUp");
    },
    closeInviteUpdatePopUp() {
      this.inviteDetails = {};
      this.$bvModal.hide("inviateUpdatePopUp");
    },
    

    openEditPopUp(index) {
      const details = this.onlineAdmissionList[index];

      this.candidateEditDetails = details;

      this.studentObj = {};
      const studentDetails = this.onlineAdmissionList[index];
      const cloneStudentDetails = { ...studentDetails };
      this.studentObj = cloneStudentDetails;
      this.studentObj.comments = this.studentObj.comments || "";

      if (this.studentObj.dob) {
        this.studentObj.dob = this.studentObj.dob.substring(0, 10);
      }
      if (this.studentObj.doj) {
        this.studentObj.doj = this.studentObj.doj.substring(0, 10);
      }
      if (this.studentObj.dateOfLeaving) {
        this.studentObj.dateOfLeaving = this.studentObj.dateOfLeaving.substring(
          0,
          10
        );
      }

      if (this.studentObj.admissionStatus == "Approved") {
        this.statusList.push("Approved");
      }

      if (this.studentObj.admissionStatus == "Rejected") {
        this.statusList.push("Rejected");
      }
      if (this.studentObj.admissionStatus == "Canceled") {
        this.statusList.push("Canceled");
      }

      if (this.studentObj.admissionStatus == "Confirm") {
        this.statusList.push("Confirm");
      }

      this.$bvModal.show("addstudpop");
    },
    openViewPopUp(index) {
      
      const details = this.onlineAdmissionList[index];

      this.candidateEditDetails = details;

      this.studentObj = {};
      const studentDetails = this.onlineAdmissionList[index];
      const cloneStudentDetails = { ...studentDetails };
      this.studentObj = cloneStudentDetails;
      this.studentObj.comments = this.studentObj.comments || "";

      if (this.studentObj.dob) {
        this.studentObj.dob = this.studentObj.dob.substring(0, 10);
      }
      if (this.studentObj.doj) {
        this.studentObj.doj = this.studentObj.doj.substring(0, 10);
      }
      if (this.studentObj.dateOfLeaving) {
        this.studentObj.dateOfLeaving = this.studentObj.dateOfLeaving.substring(
          0,
          10
        );
      }

      if (this.studentObj.admissionStatus == "Approved") {
        this.statusList.push("Approved");
      }

      if (this.studentObj.admissionStatus == "Rejected") {
        this.statusList.push("Rejected");
      }
      if (this.studentObj.admissionStatus == "Canceled") {
        this.statusList.push("Canceled");
      }

      if (this.studentObj.admissionStatus == "Confirm") {
        this.statusList.push("Confirm");
      }

      this.$bvModal.show("viewstudpop");
    },

    async updateFile(name,docType) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
      this.$toasted.error("Please login and do the action");
      } else {
      const docFileDetails = this.$refs[docType][0].files[0]
      const fd = new FormData();
      fd.append('doc', docFileDetails, docFileDetails.name,docFileDetails.actType);
      const response = await ViService.viPutOnly(`/document/parentPortal/uploadFile/${this.contactDetails._id}/${name}`,fd,userData.token);
      
      if (response.isSuccess) {
        this.$toasted.success('Upload Successful');
       // contactDetails.eligibilityCriteriaDocuments
        this.contactDetails.eligibilityCriteriaDocuments = response.data
          this.getNewOnlineAdmissionList();
      } else {
        this.$toasted.error(response.message);
          if(response.message == 'Your session has expired, please login'){
              localStorage.removeItem('user');
              this.$router.push('/login');
        }
      }
    //  document.getElementById("importExportID").value=null; 

      }

      },
    openEditDocumentPopUp(index) {
      const details = this.onlineAdmissionList[index];

      this.candidateEditDetails = details;

      this.studentObj = {};
      const studentDetails = this.onlineAdmissionList[index];
      const cloneStudentDetails = { ...studentDetails };
      this.studentObj = cloneStudentDetails;
      this.studentObj.comments = this.studentObj.comments || "";

      if (this.studentObj.dob) {
        this.studentObj.dob = this.studentObj.dob.substring(0, 10);
      }
      if (this.studentObj.doj) {
        this.studentObj.doj = this.studentObj.doj.substring(0, 10);
      }
      if (this.studentObj.dateOfLeaving) {
        this.studentObj.dateOfLeaving = this.studentObj.dateOfLeaving.substring(
          0,
          10
        );
      }

      if (this.studentObj.admissionStatus == "Approved") {
        this.statusList.push("Approved");
      }

      if (this.studentObj.admissionStatus == "Rejected") {
        this.statusList.push("Rejected");
      }
      if (this.studentObj.admissionStatus == "Canceled") {
        this.statusList.push("Canceled");
      }

      if (this.studentObj.admissionStatus == "Confirm") {
        this.statusList.push("Confirm");
      }
      this.addMoreDocumentsPopUp();
    },
    ///this.$bvModal.show("addstudpop");
    closePoup() {
      this.$bvModal.hide("addstudpop");
      //this.clearForm();
    },
    openPoup() {
      this.currentTab = 1;
      this.isEdit = true
      this.isShowEditIcon = false

      this.currentPopUpTab = 1,
      this.$bvModal.show("newstudpop");
      this.currentTab = 1;

      //this.clearForm();
    },
    closeUpdatePoup() {
      this.$bvModal.hide("addstudpop");
    },
    closeViewPoup() {
      this.$bvModal.hide("viewstudpop");
    },
    closeActionPopUp() {
      this.$bvModal.hide("actionPopup");
      (this.updateActionId = null), (this.updateActionType = null);
    },
    closeCancelActionPopUp() {
      this.$bvModal.hide("actionCancelPopup");
      (this.updateActionId = null), (this.updateActionType = null);
    },

    async confirmAddDocuments() {
      const cloneEligibilityCriteriaDocuments =
        this.studentObj.eligibilityCriteriaDocuments;

      for (let x of this.eligibilityCriteria) {
        cloneEligibilityCriteriaDocuments.push({
          name: x,
          apiName: x.replace(/\s/g, "_"),
          path: "",
          value: "",
        });
      }

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let obj = {
          eligibilityCriteriaDocuments: cloneEligibilityCriteriaDocuments,
          admissionStatus: "Documents Missing",
          comments: "Documents Missing",
        };

        const response = await ViService.viXPut(
          `/admission/updateOnlineAdmission/${this.studentObj._id}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$bvModal.hide("addDocuments");
          this.eligibilityCriteria = [];
          this.getOnlineAdmissionList("old");
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async sendInviate() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let allowSave = false;
        let errorMessage = "";

        let obj = {};

        if (this.offline == "Online") {
          if (!this.inviteDetails.schedule.link) {
            allowSave = true;
            errorMessage = "Please enter meeting URL";
          } else if (!this.inviteDetails.schedule.meetingDate) {
            allowSave = true;
            errorMessage = "Please select meeting date";
          } else if (!this.inviteDetails.schedule.meeatingTime) {
            allowSave = true;
            errorMessage = "Please select meeting time";
          } else {
            (obj["type"] = this.offline),
              (obj["link"] = this.inviteDetails.schedule.link),
              (obj["message"] = "");
            obj["meetingDate"] = this.inviteDetails.schedule.meetingDate;
            obj["meeatingTime"] = this.inviteDetails.schedule.meeatingTime;

            allowSave = false;
          }
        } else {
          if (!this.inviteDetails.schedule.message) {
            allowSave = true;
            errorMessage = "Please enter mesage";
          } else {
            (obj["type"] = this.offline),
              (obj["link"] = ""),
              (obj["meetingDate"] = null);
            obj["meeatingTime"] = "";
            obj["message"] = this.inviteDetails.schedule.message;
            allowSave = false;
          }
        }
        if (!allowSave) {
          let finalObj = {
            schedule: obj,
            admissionStatus: "Interview Scheduled",
            parent: this.inviteDetails.parent,
          };

          const response = await ViService.viXPut(
            `/admission/sendInviate/updateOnlineAdmission/${this.inviteDetails._id}`,
            finalObj,
            userData.token
          );

          if (response.isSuccess) {
            this.closeInvitePopUp();
            this.closeInviteUpdatePopUp()
            this.getNewOnlineAdmissionList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        } else {
          this.$toasted.error(errorMessage);
        }
      }
    },
    async reSendInviate() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let allowSave = false;
        let errorMessage = "";

        let obj = {};

        if (this.offline == "Online") {
          if (!this.inviteDetails.schedule.link) {
            allowSave = true;
            errorMessage = "Please enter meeting URL";
          } else if (!this.inviteDetails.schedule.meetingDate) {
            allowSave = true;
            errorMessage = "Please select meeting date";
          } else if (!this.inviteDetails.schedule.meeatingTime) {
            allowSave = true;
            errorMessage = "Please select meeting time";
          } else {
            (obj["type"] = this.offline),
              (obj["link"] = this.inviteDetails.schedule.link),
              (obj["message"] = "");
            obj["meetingDate"] = this.inviteDetails.schedule.meetingDate;
            obj["meeatingTime"] = this.inviteDetails.schedule.meeatingTime;

            allowSave = false;
          }
        } else {
          if (!this.inviteDetails.schedule.message) {
            allowSave = true;
            errorMessage = "Please enter mesage";
          } else {
            (obj["type"] = this.offline),
              (obj["link"] = ""),
              (obj["meetingDate"] = null);
            obj["meeatingTime"] = "";
            obj["message"] = this.inviteDetails.schedule.message;
            allowSave = false;
          }
        }
        if (!allowSave) {
          let finalObj = {
            schedule: obj,
            admissionStatus: "Interview Rescheduled",
            parent: this.inviteDetails.parent,
          };

          const response = await ViService.viXPut(
            `/admission/sendInviate/updateOnlineAdmission/${this.inviteDetails._id}`,
            finalObj,
            userData.token
          );

          if (response.isSuccess) {
            this.closeInvitePopUp();
            this.closeInviteUpdatePopUp()
            this.getNewOnlineAdmissionList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        } else {
          this.$toasted.error(errorMessage);
        }
      }
    },
    

    closeAddDocuments() {
      this.eligibilityCriteria = [];
      this.$bvModal.hide("addDocuments");
    },
    closeMoreInfo() {
      this.comments = "";
      this.$bvModal.hide("needMoreInfoPopUp");
    },
   async saveLastAcademicDetails(){
      let obj = {
        lastSchoolGrade: this.contactDetails.lastSchoolGrade,
        lastSchoolCity : this.contactDetails.lastSchoolCity,
        lastSchoolCountry : this.contactDetails.lastSchoolCountry,
        previousSchool : this.contactDetails.previousSchool
       };
 
       let userData = secureUI.sessionGet("user");
 
       if (!userData) {
         this.$toasted.error("Please login and do the action");
       } else {
         const response = await ViService.viXPut(
           `/admission/updateOnlineAdmission/${this.contactDetails._id}`,
           obj,
           userData.token
         );
 
         if (response.isSuccess) {
           this.currentPopUpTab = 5
 
          // this.$toasted.success(response.message);
 
         } else {
           this.$toasted.error(response.message);
           if (response.message == "Your session has expired, please login") {
             localStorage.removeItem("user");
             this.$router.push("/login");
           }
         }
       }
    },
    async saveContactAddress(){

      let obj = {
       
      permentpostalCode: this.contactDetails.permentpostalCode,
      permentdistrict : this.contactDetails.permentdistrict,
      permentstate : this.contactDetails.permentstate,
      permentcountry : this.contactDetails.permentcountry,
      permentaddressTwo : this.contactDetails.permentaddressTwo,
      permentaddressOne : this.contactDetails.permentaddressOne,
      postalCode : this.contactDetails.postalCode,
      district : this.contactDetails.district,
      state : this.contactDetails.state,
      country : this.contactDetails.country,
      addressTwo : this.contactDetails.addressTwo,
      addressOne : this.contactDetails.addressOne,
      isPermentAddress: this.contactDetails.isPermentAddress

      };

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/admission/updateOnlineAdmission/${this.contactDetails._id}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.currentPopUpTab = 4

          //this.$toasted.success(response.message);

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async updateApplication(){

   

    let userData = secureUI.sessionGet("user");

    if (!userData) {
      this.$toasted.error("Please login and do the action");
    } else {

      let obj = {
    
    firstName: this.contactDetails.firstName,
    lastName : this.contactDetails.lastName,
    dob : this.contactDetails.dob,
    gender : this.contactDetails.gender,
    placeofBirth : this.contactDetails.placeofBirth,
    aadharNo : this.contactDetails.aadharNo,
    studentPhoneNumber : this.contactDetails.studentPhoneNumber,
    otherLanguage : this.contactDetails.otherLanguage,
    email : this.contactDetails.email,
    class : this.contactDetails.class
 };

    let getclassName = this.classList.find((x) => x._id == this.contactDetails.class);
    
       if (getclassName && getclassName.className) {
         obj['className'] = getclassName.className;
       }


      const response = await ViService.viXPut(
        `/admission/updateOnlineAdmission/${this.contactDetails._id}`,
        obj,
        userData.token
      );

      if (response.isSuccess) {
        this.currentPopUpTab = 2

        //this.$toasted.success(response.message);

      } else {
        this.$toasted.error(response.message);
        if (response.message == "Your session has expired, please login") {
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      }
    }
    },
    async changeStatus(id, status) {
      //  let status = ''
      // if(this.updateActionType == 'Accept'){
      //   status = 'Application Accepted'
      // }
      // if(this.updateActionType == 'Reject'){
      //   status = 'Application Rejected'
      // }

      let obj = {
        admissionStatus: status,
      };

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/admission/updateOnlineAdmission/${id}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          // this.closeActionPopUp();
          this.getNewOnlineAdmissionList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async actionConfirm() {
      let status = "";
      if (this.updateActionType == "Accept") {
        status = "Application Accepted";
      }
      if (this.updateActionType == "Reject") {
        status = "Application Rejected";
      }

      let obj = {
        admissionStatus: status,
      };

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/admission/updateOnlineAdmission/${this.updateActionId}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.closeActionPopUp();
          this.getNewOnlineAdmissionList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async actionPreviousStage(value,id) {
    
      let obj = {
        admissionStatus: value,
      };

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/admission/updateOnlineAdmission/${id}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
         // this.closeActionPopUp();
          this.getNewOnlineAdmissionList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    
    async actionCancelConfirm() {
      let obj = {
        actionComments: this.actionComments,
        admissionStatus: this.updateActionType,
      };

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/admission/updateOnlineAdmission/${this.updateActionId}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.closeCancelActionPopUp();
          this.getOnlineAdmissionList("old");
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    getSelectRange() {
      if (this.selectAcademicYear) {
        this.getOnlineAdmissionList("old");
      } else {
        this.selectAcademicYear = null;
        this.getOnlineAdmissionList("old");
      }
    },

    setPermentAddress(value){

      if(value){
              this.contactDetails.permentaddressOne  = this.contactDetails.addressOne,
              this.contactDetails.permentaddressTwo = this.contactDetails.addressTwo,
              this.contactDetails.permentstate = this.contactDetails.state,
              this.contactDetails.permentdistrict = this.contactDetails.district,
              this.contactDetails.permentpostalCode = this.contactDetails.postalCode,
              this.contactDetails.permentcountry = this.contactDetails.country
      }else{
      this.contactDetails.permentaddressOne  = "",
              this.contactDetails.permentaddressTwo = "",
              this.contactDetails.permentstate = "",
              this.contactDetails.permentdistrict = "",
              this.contactDetails.permentpostalCode = "",
              this.contactDetails.permentcountry = 'India'
      }
      
        },
    async updateDetails() {
      let obj = {
        admissionStatus: this.studentObj.admissionStatus,
      };

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/admission/updateOnlineAdmission/${this.studentObj._id}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.closeUpdatePoup();
          this.getOnlineAdmissionList("old");
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    verifyPrimaryMailCode() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let code = this.generatedVerifyCode.toString();

        if (code == this.userVerifyCode) {
         // this.isAlreadyLogin = true;
          //   deleteFeeTypePopup
          // this.verfiyMailId = '',
          // this.verfiyMailStatus = '',
          // this.isVerifyCode = false;
          this.generatedVerifyCode = null;
          //this.verifyedParentDetails = {}
          // this.isAlreadyLogin = true
          this.$bvModal.hide("deleteFeeTypePopup");

          this.$toasted.success("Verified");
        } else {
          this.$toasted.error("Invalid Code");
        }
      }
    },
    async checkmailValid(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let obj = {
          email: value,
        };
        ViService.viXPost(
          "/contact/validateMail/parentPortal/apply",
          obj,
          userData.token
        )
          .then((res) => {
            if (res.isSuccess) {
              console.log("res", res);
             // this.isVerifyCode = true;
               this.guestParentUpdate(res.data.data._id)
              this.generatedVerifyCode = res.data.verifyCode;
              this.verifyedParentDetails = res.data.data;
              this.$bvModal.hide("deleteFeeTypePopup");

             // this.$toasted.success(res.message);
            } else {
              this.$toasted.error(res.message);
            }
          })
          .catch((e) => {
            let eData = errorLog.apiErrorLog(e);
            this.$toasted.error(eData);
          });
      }
    },
    async guestParentUpdate(Id) {
      

      let obj = {
        parentId: Id

      };

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/admission/updateParentOnlineAdmission/${this.contactDetails._id}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.contactDetails['parentId'] = Id
          this.userDetails['_id'] = Id
          
        this.userDetails.firstName = response.data.firstName || "",
        this.userDetails.lastName  = response.data.lastName ||  "",
        this.userDetails.fatherName  = response.data.fatherName ||  "",
        this.userDetails.motherName  = response.data.motherName ||  "",
        this.userDetails.email  = response.data.email ||  "",
        this.userDetails.phoneNo  = response.data.phoneNo ||  null,
       
        this.userDetails.occupation  = response.data.occupation ||  "",
        this.userDetails.religion  = response.data.religion ||  "",
        this.userDetails.nationality  = response.data.nationality ||  "",
        this.userDetails.address  = response.data.address || "",
       
        this.userDetails.education  = response.data.education ||  '',
        this.userDetails.dob  = response.data.dob ? response.data.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = response.data.aadharCardNo ||  ''
       // this.userDetails['_id']  = details.parent._id ||  null

       this.isAlreadyLogin = false;

          this.currentPopUpTab = 3

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async submitApplication() {
      let isFormValid = false;

      await this.$validator.validateAll("studentvalidateOne").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let getclassName = this.classList.find(
            (x) => x._id == this.contactDetails.class
          );
          if (getclassName && getclassName.className) {
            this.contactDetails.className = getclassName.className;
          }
          if(this.isAlreadyLogin){
           // this.verifyedParentDetails = {};
            this.contactDetails['verifyedParentDetails'] = this.verifyedParentDetails

          }

          this.contactDetails['isAlreadyLogin'] = this.isAlreadyLogin

          this.contactDetails["parentDetails"] = this.userDetails;

          
          ViService.viXPost(
            "/contact/student/parentPortal/apply",
            this.contactDetails,
            userData.token
          )
            .then((res) => {
              if (res.isSuccess) {
                if(res.data && res.data._id)
                this.contactDetails['_id'] = res.data._id
                this.currentPopUpTab = 2

                if(res.data && res.data.eligibilityCriteriaDocuments && res.data.eligibilityCriteriaDocuments.length > 0){
                this.contactDetails.eligibilityCriteriaDocuments = res.data.eligibilityCriteriaDocuments

                }
               // this.$toasted.success(res.message);

                // this.closeAdmissionPoup();
                // this.getNewOnlineAdmissionList();
                //  this.$router.push("/parent/admission");
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
        }
        // }
      }
    },
    async saveParentDetails() {
      let isFormValid = false;

      await this.$validator.validateAll("parentvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
         

          this.contactDetails['isAlreadyLogin'] = false

          let obj = {
            student: this.contactDetails,
            user: this.userDetails
          }
          // this.contactDetails["parentDetails"] = this.userDetails;

          ViService.viXPost(
            "/contact/createGuestParent",
            obj,
            userData.token
          )
            .then((res) => {
              if (res.isSuccess) {
                if(res.data && res.data._id){
                  this.userDetails['_id'] = res.data._id
                this.contactDetails['parentId'] = res.data._id
                this.currentPopUpTab = 3
                // this.contactDetails['_id'] = res.data._id
                // this.currentPopUpTab = 2
                }
              
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
        }
        // }
      }
    },
    async updateParentDetails() {
      let isFormValid = false;

      await this.$validator.validateAll("parentvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let obj = {
            student: this.contactDetails,
            user: this.userDetails
          }
          // this.contactDetails["parentDetails"] = this.userDetails;
          
          ViService.viXPost(
            "/contact/updateGuestParent",
            obj,
            userData.token
          )
            .then((res) => {
              if (res.isSuccess) {
                console.log("sdsdsd",res)
               // if(res.data && res.data._id){

              //  this.contactDetails['parentId'] = res.data._id
                this.currentPopUpTab = 3
              
               // }
              
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
        }
        // }
      }
    },
    
    async editAction(id,index,type){
      this.isShowEditIcon = true
      this.isEdit = true

      if(type == 'newApplicationList'){
        const details = this.newApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        this.contactDetails.email  = details.email ||  "",
      
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
       
       this.contactDetails.class = details.class || null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

     
      if(details.parentId && details.parent && details.parent._id){
        this.showisAlreadyLogin = true

        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
       
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
      
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }
      this.contactDetails.eligibilityCriteriaDocuments = details.eligibilityCriteriaDocuments

      }
      if(type == 'acceptedApplicationList'){
        const details = this.acceptedApplicationList[index]

      //  this.contactDetails. = {
        // middleName : "",
        // fatherFirstName : "",
        // fatherLastName : "",
        // fatherOccuption : "",
        // motherFirstName : "",
        // motherLastName : "",
        // motherOccuption : "",
        // guardianFirstName : "",
        // guardianLastName : "",
        // guardianOccuption : "",
        // mobileThree: null,
        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
        // isrtequota: false,
        // isDisability: false,
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
       // this.contactDetails.doj  = details.isPermentAddress ||  null,
        //dateOfLeaving: null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       // academicYear: null,
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        //accountId: null,
        this.contactDetails.email  = details.email ||  "",
        // mobileOne: 0,
        // mobileTwo: 0,
      //  image: "",
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      //this.contactDetails.classApplied: "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
        //admissionStatus: "Completed",
       // siblings: false,
       // rollNoAllocated: "",
       this.contactDetails.class = details.class || null,
     //  this.contactDetails.classSection: null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      //  this.contactDetails.siblingName  = details.isPermentAddress ||  null,
      //  this.contactDetails.siblingRollNo  = details.isPermentAddress ||  "",
      //  this.contactDetails.siblingDiscount: "",
        //className: "",
        // this.contactDetails.admissionDeadline: null,
        // this.contactDetails.eligibilityCriteria: [],
      // },
      if(details.parentId && details.parent && details.parent._id){
        this.showisAlreadyLogin = true

       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }

      }
      if(type == 'rejectedApplicationList'){
        const details = this.rejectedApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        this.contactDetails.email  = details.email ||  "",
      
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
       
       this.contactDetails.class = details.class || null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

     
      if(details.parentId && details.parent && details.parent._id){
        this.showisAlreadyLogin = true

        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
       
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
      
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }
      this.contactDetails.eligibilityCriteriaDocuments = details.eligibilityCriteriaDocuments

        console.log("details",details)
      }
      if(type == 'scheduledApplicationList'){
        const details = this.scheduledApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        this.contactDetails.email  = details.email ||  "",
      
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
       
       this.contactDetails.class = details.class || null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

     
      if(details.parentId && details.parent && details.parent._id){
        this.showisAlreadyLogin = true

        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
       
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
      
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }
      this.contactDetails.eligibilityCriteriaDocuments = details.eligibilityCriteriaDocuments

        console.log("details",details)
      }
      if(type == 'rejectInInterviewApplicationList'){
        const details = this.rejectInInterviewApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        this.contactDetails.email  = details.email ||  "",
      
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
       
       this.contactDetails.class = details.class || null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

     
      if(details.parentId && details.parent && details.parent._id){
        this.showisAlreadyLogin = true

        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
       
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
      
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }
      this.contactDetails.eligibilityCriteriaDocuments = details.eligibilityCriteriaDocuments

        console.log("details",details)
      }
      if(type == 'waitingListApplicationList'){
        const details = this.waitingListApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        this.contactDetails.email  = details.email ||  "",
      
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
       
       this.contactDetails.class = details.class || null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

     
      if(details.parentId && details.parent && details.parent._id){
        this.showisAlreadyLogin = true

        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
       
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
      
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }
      this.contactDetails.eligibilityCriteriaDocuments = details.eligibilityCriteriaDocuments

        console.log("details",details)
      }
      // if(type == 'newApplicationList'){
      //   const details = this.newApplicationList[index]

      //   this.contactDetails.isPermentAddress = details.isPermentAddress || false,
      //   this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
      //   this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
      //   this.contactDetails.permentstate  = details.permentstate ||  "",
      //   this.contactDetails.permentdistrict  = details.permentdistrict || "",
      //   this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
      //   this.contactDetails.permentcountry  = details.permentcountry ||  "",
      //   this.contactDetails.category  = details.category ||  "",
      //   this.contactDetails.previousSchool  = details.previousSchool ||  "",
      //   this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
      //   this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
      //   this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
      //   this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
      //   this.contactDetails.firstName  = details.firstName ||  "",
      //   this.contactDetails.lastName  = details.lastName ||  "",
      //   //this.contactDetails.admissionType: "online",
      //   this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
      //   this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
      //  this.contactDetails.gender  = details.gender ||  "Male",
      //  this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
      //  this.contactDetails.aadharNo  = details.aadharNo ||  '',
      //  this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
      //  this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
      //   this.contactDetails.email  = details.email ||  "",
      
      // this.contactDetails.addressOne  = details.addressOne ||  "",
      // this.contactDetails.addressTwo  = details.addressTwo ||  "",
      // this.contactDetails.state  = details.state ||  "",
      // this.contactDetails.district  = details.district ||  "",
      // this.contactDetails.postalCode  = details.postalCode ||  "",
      // this.contactDetails.country  = details.country ||  "",
      // this.contactDetails.bloodGroup = details.bloodGroup || null,
       
      //  this.contactDetails.class = details.class || null,
      //  this.contactDetails.parentId  = details.parentId ||  null,
      //  this.contactDetails['_id']  = details._id ||  null

     
      // if(details.parentId && details.parent && details.parent._id){
      //   this.showisAlreadyLogin = true

      //   this.userDetails.firstName = details.parent.firstName || "",
      //   this.userDetails.lastName  = details.parent.lastName ||  "",
      //   this.userDetails.fatherName  = details.parent.fatherName ||  "",
      //   this.userDetails.motherName  = details.parent.motherName ||  "",
      //   this.userDetails.email  = details.parent.email ||  "",
      //   this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
       
      //   this.userDetails.occupation  = details.parent.occupation ||  "",
      //   this.userDetails.religion  = details.parent.religion ||  "",
      //   this.userDetails.nationality  = details.parent.nationality ||  "",
      //   this.userDetails.address  = details.parent.address || "",
      
      //   this.userDetails.education  = details.parent.education ||  '',
      //   this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
      //   this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
      //   this.userDetails['_id']  = details.parent._id ||  null
       
      
      // }
      // this.contactDetails.eligibilityCriteriaDocuments = details.eligibilityCriteriaDocuments

      //   console.log("details",details)
      // }
      // if(type == 'newApplicationList'){
      //   const details = this.newApplicationList[index]

      //   this.contactDetails.isPermentAddress = details.isPermentAddress || false,
      //   this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
      //   this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
      //   this.contactDetails.permentstate  = details.permentstate ||  "",
      //   this.contactDetails.permentdistrict  = details.permentdistrict || "",
      //   this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
      //   this.contactDetails.permentcountry  = details.permentcountry ||  "",
      //   this.contactDetails.category  = details.category ||  "",
      //   this.contactDetails.previousSchool  = details.previousSchool ||  "",
      //   this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
      //   this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
      //   this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
      //   this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
      //   this.contactDetails.firstName  = details.firstName ||  "",
      //   this.contactDetails.lastName  = details.lastName ||  "",
      //   //this.contactDetails.admissionType: "online",
      //   this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
      //   this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
      //  this.contactDetails.gender  = details.gender ||  "Male",
      //  this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
      //  this.contactDetails.aadharNo  = details.aadharNo ||  '',
      //  this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
      //  this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
      //   this.contactDetails.email  = details.email ||  "",
      
      // this.contactDetails.addressOne  = details.addressOne ||  "",
      // this.contactDetails.addressTwo  = details.addressTwo ||  "",
      // this.contactDetails.state  = details.state ||  "",
      // this.contactDetails.district  = details.district ||  "",
      // this.contactDetails.postalCode  = details.postalCode ||  "",
      // this.contactDetails.country  = details.country ||  "",
      // this.contactDetails.bloodGroup = details.bloodGroup || null,
       
      //  this.contactDetails.class = details.class || null,
      //  this.contactDetails.parentId  = details.parentId ||  null,
      //  this.contactDetails['_id']  = details._id ||  null

     
      // if(details.parentId && details.parent && details.parent._id){
      //   this.showisAlreadyLogin = true

      //   this.userDetails.firstName = details.parent.firstName || "",
      //   this.userDetails.lastName  = details.parent.lastName ||  "",
      //   this.userDetails.fatherName  = details.parent.fatherName ||  "",
      //   this.userDetails.motherName  = details.parent.motherName ||  "",
      //   this.userDetails.email  = details.parent.email ||  "",
      //   this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
       
      //   this.userDetails.occupation  = details.parent.occupation ||  "",
      //   this.userDetails.religion  = details.parent.religion ||  "",
      //   this.userDetails.nationality  = details.parent.nationality ||  "",
      //   this.userDetails.address  = details.parent.address || "",
      
      //   this.userDetails.education  = details.parent.education ||  '',
      //   this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
      //   this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
      //   this.userDetails['_id']  = details.parent._id ||  null
       
      
      // }
      // this.contactDetails.eligibilityCriteriaDocuments = details.eligibilityCriteriaDocuments

      //   console.log("details",details)
      // }

      
      this.$bvModal.show("newstudpop");

    },
    async viewAction(id,index,type){
      this.isShowEditIcon = true

      this.showisAlreadyLogin = true

      if(type == 'newApplicationList'){
        const details = this.newApplicationList[index]
console.log("details",details)
     
        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        this.contactDetails.email  = details.email ||  "",
     
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
     
       this.contactDetails.class = details.class || null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      if(details.parentId && details.parent && details.parent._id){
       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }
      this.contactDetails.eligibilityCriteriaDocuments = details.eligibilityCriteriaDocuments

        console.log("details",details)
      }
      if(type == 'acceptedApplicationList'){
        const details = this.acceptedApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
       
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
       // this.contactDetails.doj  = details.isPermentAddress ||  null,
        //dateOfLeaving: null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       // academicYear: null,
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        //accountId: null,
        this.contactDetails.email  = details.email ||  "",
        // mobileOne: 0,
        // mobileTwo: 0,
      //  image: "",
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      //this.contactDetails.classApplied: "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
        //admissionStatus: "Completed",
       // siblings: false,
       // rollNoAllocated: "",
       this.contactDetails.class = details.class || null,
     //  this.contactDetails.classSection: null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      //  this.contactDetails.siblingName  = details.isPermentAddress ||  null,
      //  this.contactDetails.siblingRollNo  = details.isPermentAddress ||  "",
      //  this.contactDetails.siblingDiscount: "",
        //className: "",
        // this.contactDetails.admissionDeadline: null,
        // this.contactDetails.eligibilityCriteria: [],
      // },
      if(details.parentId && details.parent && details.parent._id){
       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }

        console.log("details",details)
      }
      if(type == 'rejectedApplicationList'){
        const details = this.rejectedApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
       
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
       // this.contactDetails.doj  = details.isPermentAddress ||  null,
        //dateOfLeaving: null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       // academicYear: null,
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        //accountId: null,
        this.contactDetails.email  = details.email ||  "",
        // mobileOne: 0,
        // mobileTwo: 0,
      //  image: "",
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      //this.contactDetails.classApplied: "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
        //admissionStatus: "Completed",
       // siblings: false,
       // rollNoAllocated: "",
       this.contactDetails.class = details.class || null,
     //  this.contactDetails.classSection: null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      //  this.contactDetails.siblingName  = details.isPermentAddress ||  null,
      //  this.contactDetails.siblingRollNo  = details.isPermentAddress ||  "",
      //  this.contactDetails.siblingDiscount: "",
        //className: "",
        // this.contactDetails.admissionDeadline: null,
        // this.contactDetails.eligibilityCriteria: [],
      // },
      if(details.parentId && details.parent && details.parent._id){
       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }

        console.log("details",details)
      }
      if(type == 'scheduledApplicationList'){
        const details = this.scheduledApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
       
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
       // this.contactDetails.doj  = details.isPermentAddress ||  null,
        //dateOfLeaving: null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       // academicYear: null,
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        //accountId: null,
        this.contactDetails.email  = details.email ||  "",
        // mobileOne: 0,
        // mobileTwo: 0,
      //  image: "",
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      //this.contactDetails.classApplied: "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
        //admissionStatus: "Completed",
       // siblings: false,
       // rollNoAllocated: "",
       this.contactDetails.class = details.class || null,
     //  this.contactDetails.classSection: null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      //  this.contactDetails.siblingName  = details.isPermentAddress ||  null,
      //  this.contactDetails.siblingRollNo  = details.isPermentAddress ||  "",
      //  this.contactDetails.siblingDiscount: "",
        //className: "",
        // this.contactDetails.admissionDeadline: null,
        // this.contactDetails.eligibilityCriteria: [],
      // },
      if(details.parentId && details.parent && details.parent._id){
       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }

        console.log("details",details)
      }
      if(type == 'rejectInInterviewApplicationList'){
        const details = this.rejectInInterviewApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
       
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
       // this.contactDetails.doj  = details.isPermentAddress ||  null,
        //dateOfLeaving: null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       // academicYear: null,
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        //accountId: null,
        this.contactDetails.email  = details.email ||  "",
        // mobileOne: 0,
        // mobileTwo: 0,
      //  image: "",
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      //this.contactDetails.classApplied: "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
        //admissionStatus: "Completed",
       // siblings: false,
       // rollNoAllocated: "",
       this.contactDetails.class = details.class || null,
     //  this.contactDetails.classSection: null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      //  this.contactDetails.siblingName  = details.isPermentAddress ||  null,
      //  this.contactDetails.siblingRollNo  = details.isPermentAddress ||  "",
      //  this.contactDetails.siblingDiscount: "",
        //className: "",
        // this.contactDetails.admissionDeadline: null,
        // this.contactDetails.eligibilityCriteria: [],
      // },
      if(details.parentId && details.parent && details.parent._id){
       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }

        console.log("details",details)
      }
      if(type == 'waitingListApplicationList'){
        const details = this.waitingListApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
       
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
       // this.contactDetails.doj  = details.isPermentAddress ||  null,
        //dateOfLeaving: null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       // academicYear: null,
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        //accountId: null,
        this.contactDetails.email  = details.email ||  "",
        // mobileOne: 0,
        // mobileTwo: 0,
      //  image: "",
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      //this.contactDetails.classApplied: "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
        //admissionStatus: "Completed",
       // siblings: false,
       // rollNoAllocated: "",
       this.contactDetails.class = details.class || null,
     //  this.contactDetails.classSection: null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      //  this.contactDetails.siblingName  = details.isPermentAddress ||  null,
      //  this.contactDetails.siblingRollNo  = details.isPermentAddress ||  "",
      //  this.contactDetails.siblingDiscount: "",
        //className: "",
        // this.contactDetails.admissionDeadline: null,
        // this.contactDetails.eligibilityCriteria: [],
      // },
      if(details.parentId && details.parent && details.parent._id){
       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }

        console.log("details",details)
      }
      if(type == 'admissionApprovedApplicationList'){
        const details = this.admissionApprovedApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
       
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
       // this.contactDetails.doj  = details.isPermentAddress ||  null,
        //dateOfLeaving: null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       // academicYear: null,
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        //accountId: null,
        this.contactDetails.email  = details.email ||  "",
        // mobileOne: 0,
        // mobileTwo: 0,
      //  image: "",
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      //this.contactDetails.classApplied: "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
        //admissionStatus: "Completed",
       // siblings: false,
       // rollNoAllocated: "",
       this.contactDetails.class = details.class || null,
     //  this.contactDetails.classSection: null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      //  this.contactDetails.siblingName  = details.isPermentAddress ||  null,
      //  this.contactDetails.siblingRollNo  = details.isPermentAddress ||  "",
      //  this.contactDetails.siblingDiscount: "",
        //className: "",
        // this.contactDetails.admissionDeadline: null,
        // this.contactDetails.eligibilityCriteria: [],
      // },
      if(details.parentId && details.parent && details.parent._id){
       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }

        console.log("details",details)
      }
      if(type == 'accountClearedApplicationList'){
        const details = this.accountClearedApplicationList[index]

        this.contactDetails.isPermentAddress = details.isPermentAddress || false,
       
        this.contactDetails.permentaddressOne   = details.permentaddressOne || "",
        this.contactDetails.permentaddressTwo  = details.permentaddressTwo ||  "",
        this.contactDetails.permentstate  = details.permentstate ||  "",
        this.contactDetails.permentdistrict  = details.permentdistrict || "",
        this.contactDetails.permentpostalCode  = details.permentpostalCode ||  "",
        this.contactDetails.permentcountry  = details.permentcountry ||  "",
        this.contactDetails.category  = details.category ||  "",
        this.contactDetails.previousSchool  = details.previousSchool ||  "",
        this.contactDetails.lastSchoolGrade  = details.lastSchoolGrade ||  '',
        this.contactDetails.lastSchoolCity  = details.lastSchoolCity ||  '',
        this.contactDetails.lastSchoolCountry  = details.lastSchoolCountry ||  '',
        this.contactDetails.previousClassPassed  = details.previousClassPassed ||  "",
        this.contactDetails.firstName  = details.firstName ||  "",
        this.contactDetails.lastName  = details.lastName ||  "",
        //this.contactDetails.admissionType: "online",
        this.contactDetails.dob  = details.dob.substring(0,10) ||  null,
       // this.contactDetails.doj  = details.isPermentAddress ||  null,
        //dateOfLeaving: null,
        this.contactDetails.pointOfContact  = details.pointOfContact ||  "Father",
       // academicYear: null,
       this.contactDetails.gender  = details.gender ||  "Male",
       this.contactDetails.placeofBirth  = details.placeofBirth ||  '',
       this.contactDetails.aadharNo  = details.aadharNo ||  '',
       this.contactDetails.studentPhoneNumber  = details.studentPhoneNumber || null,
       this.contactDetails.otherLanguage  = details.otherLanguage ||  '',
        //accountId: null,
        this.contactDetails.email  = details.email ||  "",
        // mobileOne: 0,
        // mobileTwo: 0,
      //  image: "",
      this.contactDetails.addressOne  = details.addressOne ||  "",
      this.contactDetails.addressTwo  = details.addressTwo ||  "",
      this.contactDetails.state  = details.state ||  "",
      this.contactDetails.district  = details.district ||  "",
      this.contactDetails.postalCode  = details.postalCode ||  "",
      this.contactDetails.country  = details.country ||  "",
      //this.contactDetails.classApplied: "",
      this.contactDetails.bloodGroup = details.bloodGroup || null,
        //admissionStatus: "Completed",
       // siblings: false,
       // rollNoAllocated: "",
       this.contactDetails.class = details.class || null,
     //  this.contactDetails.classSection: null,
       this.contactDetails.parentId  = details.parentId ||  null,
       this.contactDetails['_id']  = details._id ||  null

      //  this.contactDetails.siblingName  = details.isPermentAddress ||  null,
      //  this.contactDetails.siblingRollNo  = details.isPermentAddress ||  "",
      //  this.contactDetails.siblingDiscount: "",
        //className: "",
        // this.contactDetails.admissionDeadline: null,
        // this.contactDetails.eligibilityCriteria: [],
      // },
      if(details.parentId && details.parent && details.parent._id){
       // this.userDetails. {
        this.userDetails.firstName = details.parent.firstName || "",
        this.userDetails.lastName  = details.parent.lastName ||  "",
        this.userDetails.fatherName  = details.parent.fatherName ||  "",
        this.userDetails.motherName  = details.parent.motherName ||  "",
        this.userDetails.email  = details.parent.email ||  "",
        this.userDetails.phoneNo  = details.parent.phoneNo ||  null,
        //password: "",
        //role: "",
        //membership: "Free",
        //userId: "",
        //gender: "",
        this.userDetails.occupation  = details.parent.occupation ||  "",
        this.userDetails.religion  = details.parent.religion ||  "",
        this.userDetails.nationality  = details.parent.nationality ||  "",
        this.userDetails.address  = details.parent.address || "",
        ////registeredAs: "",
        //userDevices: [],
        //profile: null,
        this.userDetails.education  = details.parent.education ||  '',
        this.userDetails.dob  = details.parent.dob ? details.parent.dob.substring(0,10) : null,
        this.userDetails.aadharCardNo  = details.parent.aadharCardNo ||  ''
        this.userDetails['_id']  = details.parent._id ||  null
       
      
      }

      }
      //////////11111111111/
      
      this.isEdit = false

      this.$bvModal.show("newstudpop");

    },
    

    async confirmDelete(id) {

let userData = secureUI.sessionGet("user");

if (!userData) {
  this.$toasted.error("Please login and do the action");
  this.$router.push('/login');
} else {
  //this.viLoader = true;
  const response = await ViService.viDelete(`/admission/deleteOnlineAdmission/${id}`,userData.token);

if (response.isSuccess) {
this.getNewOnlineAdmissionList()
      this.$toasted.success(response.message);
  } else {
      this.$toasted.error(response.message);
 if(response.message == 'Your session has expired, please login'){
          localStorage.removeItem('user');
          this.$router.push('/login');
   }
  }
}
},
    async createStudent() {
      let isFormValid = false;

      await this.$validator.validateAll("studentvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viXPost(
            "/contact/student/enrollOnline/schooladmission",
            this.studentObj,
            userData.token
          );

          if (response.isSuccess) {
            this.closePoup();
            this.getNewOnlineAdmissionList()
            this.getOnlineAdmissionList("old");
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    assignSection(index) {
      this.studentObj = {};
      const studentDetails = this.accountClearedApplicationList[index];
      const cloneStudentDetails = { ...studentDetails };
      this.studentObj = cloneStudentDetails;
      this.studentObj.comments = this.studentObj.comments || "";

      //this.studentObj.admissionStatus = "Enrolled";

      if (this.studentObj.dob) {
        this.studentObj.dob = this.studentObj.dob.substring(0, 10);
      }
      if (this.studentObj.doj) {
        this.studentObj.doj = this.studentObj.doj.substring(0, 10);
      }
      if (this.studentObj.dateOfLeaving) {
        this.studentObj.dateOfLeaving = this.studentObj.dateOfLeaving.substring(
          0,
          10
        );
      }

      this.getSectionList(cloneStudentDetails.class);
      this.setPlanDate(this.studentObj.academicYear);
      this.getStudentRollNo();
    },
    async enrollSchoolAction(index) {
      this.studentObj = {};
      const studentDetails = this.onlineAdmissionList[index];
      const cloneStudentDetails = { ...studentDetails };

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXPost(
          "/contact/proceed/enrollOnline/schooladmission",
          cloneStudentDetails,
          userData.token
        );

        if (response.isSuccess) {
          //this.closePoup()
          this.getOnlineAdmissionList("old");
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async getStudentRollNo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/contact/getRollNo",
          userData.token
        );
        if (response.isSuccess) {
          const rollNo = response.data.rollno;

          if (rollNo) {
            this.studentObj["rollNoAllocated"] = rollNo;
            this.studentObj.parent.userName = response.data.username;
            this.$bvModal.show("editstudpop");
            this.showAssign = true;
          }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    setPlanDate(acdyear) {
      if (acdyear) {
        const selectAcdYear = this.academicYearList.find(
          (x) => x._id == acdyear
        );

        let start = new Date(selectAcdYear.from);
        let end = new Date(selectAcdYear.to);

        let startmonth = start.getMonth() + 1;
        let startdate = start.getDate();

        if (startdate < 10) {
          startdate = "0" + startdate;
        }
        if (startmonth < 10) {
          startmonth = "0" + startmonth;
        }

        this.minPlanDate =
          start.getFullYear() + "-" + startmonth + "-" + startdate;

        let endmonth = end.getMonth() + 1;

        let enddate = new Date(end.getFullYear(), endmonth, 0).getDate();

        if (endmonth < 10) {
          endmonth = "0" + endmonth;
        }

        this.maxPlanDate = end.getFullYear() + "-" + endmonth + "-" + enddate;
      }
    },
    closePoup() {
      this.$bvModal.hide("editstudpop");
      this.showAssign = false;
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getSectionList(classId) {
      if (classId) {
        this.sectionList = [];
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viXGet(
            `/contact/getSectionList?class=${classId}`,
            userData.token
          );
          if (response.isSuccess) {
            this.sectionList = secureUI.secureGet(response.data);

            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    async getOnlineAdmissionList(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (type == "new") {
          this.viLoader = true;
        }
        // this.viLoader = true;
        const response = await ViService.viXGet(
          `/admission/getOnlineAdmissionListView?academicYear=${this.selectAcademicYear}&skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.onlineAdmissionList = resultData.onlineAdmissionList;
          if (this.countAll) {
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount / this.limit);
          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    async getNewOnlineAdmissionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        // if (type == "new") {
        //   this.viLoader = true;
        // }
        // this.viLoader = true;
        const response = await ViService.viXGet(
          `/admission/getNewOnlineAdmissionListView`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          console.log("cvvccc", resultData);
          if (resultData.length > 0) {
            // this.onlineAdmissionList = resultData;
            (this.newApplicationList =
              resultData.filter((x) => x.admissionStatus == "Draft" || x.admissionStatus == 'Submitted') || []),
              (this.acceptedApplicationList =
                resultData.filter(
                  (x) => x.admissionStatus == "Application Accepted"
                ) || []),
              (this.rejectedApplicationList =
                resultData.filter(
                  (x) => x.admissionStatus == "Application Rejected"
                ) || []),
              (this.scheduledApplicationList =
                resultData.filter((x) => x.admissionStatus == "Scheduled" || x.admissionStatus == 'Interview Scheduled' || x.admissionStatus == 'Interview Rescheduled') ||
                []),
              (this.waitingListApplicationList =
                resultData.filter(
                  (x) =>
                    x.admissionStatus == "Waitlist" ||
                    x.admissionStatus == "waitlist"
                ) || []),
              (this.rejectInInterviewApplicationList =
                resultData.filter(
                  (x) => x.admissionStatus == "Reject in Interview"
                ) || []),
              (this.admissionApprovedApplicationList =
                resultData.filter(
                  (x) => x.admissionStatus == "Admission Approved"
                ) || []),

                this.accountClearedApplicationList = resultData.filter((x) => x.admissionStatus == "Payment Cleared" || x.admissionStatus == 'Added to class') || []
             // (this.accountClearedApplicationList = []);

              //accountClearedApplicationList
          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    // `/admission/getNewOnlineAdmissionListView`,
  },
  components: {
    "v-select": Select,
    ViSpinner,
  },
};
</script>
<style>
#actionPopup .modal-content {
  width: 50% !important;
  /* Could be more or less, depending on screen size */
}
</style>
